import '../vendor/jquery';

$(document).ready(function() {
	var target = $('#auth-container')[0];
	var notice = $('#legacy-auth-notice');
	
	if ($(target).length) {
	
		// Callback function to execute when mutations are observed
		var config = { attributes: false, childList: true, subtree: true };
		var callback = function(mutationsList, observer) {
    		$.each(mutationsList, function(index, mutation) {
        		const signInDiv = $(target).find(".firebaseui-id-page-sign-in");
						if (signInDiv.length > 0) {
    					notice.removeClass('hidden');
						} else {
    					notice.addClass('hidden');
						}
    		});
		};
		
		var observer = new MutationObserver(callback);
		observer.observe(target, config);
		
	}
});


