// import { auth } from "../auth";
import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import { startSpinner, stopSpinner } from "../ui/spinner";

export default class extends Controller {
  static targets = ['monthly', 'annual', 'annualSubscribeBtn', 'annualSubscribed', 'annualUpgradeBtn', 'monthlySubscribeBtn', 'monthlySubscribed', 'monthlyDowngradeBtn', 'legacyNotice', 'basicPlans', 'plusPlans'];

  initialize(){
    console.log('subscription status controller', document.referrer);
    console.log('subscription purchased');
    cookies.set('subscriptionProcessing', true, '/');
    document.getElementById('notices').innerHTML = '';
    showNotice("Hang tight! We're finalizing your subscription. Your premium content will be unlocked once the page reloads.", "process");
    startSpinner();
    function checkSubscriptionStatus() {
      fetch('/subscription/details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json'
        }
      }).then(response => response.json())
        .then(data => {
          console.log('data', data);
          if(!_.isEmpty(data)) {
            console.log('location', '/subscription?subscription-purchased=true');
            cookies.erase('subscriptionProcessing');
            window.location = '/subscription/?subscription-purchased=true';
          } else {
              setTimeout(checkSubscriptionStatus, 5000); // Poll every 5 seconds
          }
        });
    }
    checkSubscriptionStatus();
  }

  connect() {
  }

  // async getSubscriptionData(){
  //   const res = await fetch('/subscription/details', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'accept': 'application/json'
  //     }
  //   })
  //   const data = await res.json();
  //   if(_.isEmpty(data)){
  //     this.plusPlansTarget.remove();
  //     this.hideConversionBanner();
  //     return false;
  //   }
  //   // console.log('data', data);
  //   this.subscriptionDetails = data;
  //   this.toggleConversionBanner(data);
  //   const price_id = data.price_id;
  //   if(price_id){
  //   }
  // }
}