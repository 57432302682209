import '../vendor/jquery';
import '../vendor/sharer.min';

$(document).ready(function(){
	
	const title = document.title;
	const desc = $("meta[name='description']").attr("content");
	const url = window.location.href;
	
	$("#button-share").click(function(){
		if (navigator.share) {
			navigator.share({
    		title: title,
    		text: desc,
    		url: url
  		});
		} else {
			$('#share-options').toggle();
		}
	});
	
});
