import './vendor/jquery';

// UI
import './ui/auth-legacy-notice';
import './ui/app-block';
import './ui/magnify';
import { openDrawer, closeDrawer } from './ui/nav';
import './ui/notices';
import './ui/print';
import './ui/share';
import './ui/search';
import './ui/slider';
import './ui/tabs';
import { startSpinner, stopSpinner } from './ui/spinner';
import { startTimer, pauseTimer, setLap, resumeTimer, resetTimer } from './ui/timer';
import { initUnhides } from './ui/unhides';
import { initVideoPlayer } from './ui/video-player';
import './utils/sentry.js';

// Logic
import { logout, checkUserLoggedIn, docCookies } from './auth';
import './controllers';
import './utils/cookieconsent.js';

globalThis.App = {
  initVideoPlayer,
  startSpinner,
  stopSpinner,
  openDrawer,
  closeDrawer,
  logout,
  startTimer,
  pauseTimer,
  setLap,
  resumeTimer,
  resetTimer,
  initUnhides
}

const loggedInElements = [
  "#user_logout",
  "#user_account",
  "#user_account_mobile",
  "#user_activity",
  "#user_activity_mobile"
]
const loggedOutElements = [
  "#user_signin",
  "#user_signin_mobile"
]
const subscribedElements = [
]
const notSubscribedElements = [
  "#user_subscription",
  "#user_subscription_mobile"
]
const hiddenClass = "!hidden";

window.setUiToLoggedIn = function() {
  loggedInElements.forEach( function(e) {
    $(e).removeClass(hiddenClass);
  });
  loggedOutElements.forEach( function(e) {
    $(e).addClass(hiddenClass);
  });
}

window.setUiToLoggedOut = function() {
  loggedInElements.forEach( function(e) {
    $(e).addClass(hiddenClass);
  });
  loggedOutElements.forEach( function(e) {
    $(e).removeClass(hiddenClass);
  });
}

window.setUiToSubscribed = function() {
  subscribedElements.forEach( function(e) {
    $(e).removeClass(hiddenClass);
  });
  notSubscribedElements.forEach( function(e) {
    $(e).addClass(hiddenClass);
  });
}

window.setUiToNotSubscribed = function() {
  subscribedElements.forEach( function(e) {
    $(e).addClass(hiddenClass);
  });
  notSubscribedElements.forEach( function(e) {
    $(e).removeClass(hiddenClass);
  });
}

window.cookies = {
  set(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  },
  get(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },
  erase(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
};


window.showConversionBanner = function(){
  const legacyPlusCookie = cookies.get('legacyPlus');
  // console.log('legacy plus cookie', legacyPlusCookie);
  if(legacyPlusCookie){
    document.getElementById('plus-conversion-banner').classList.remove('hidden');
  }
}

window.hideConversionBanner = function(){
  cookies.erase('legacyPlus');
  document.getElementById('plus-conversion-banner').classList.add('hidden');
}

window.showSubscriptionStatusAlert = function() {
  const subscriptionPausedCookie = cookies.get('subscriptionPaused');
  if(subscriptionPausedCookie){
    showNotice("Your account is on hold. Please contact The Food Medic team to reinstate.", "notice");
  } else {
    const subscriptionProcessingCookie = cookies.get('subscriptionProcessing');
    if(subscriptionProcessingCookie){
      document.getElementById('notices').innerHTML = '';
      showNotice("Hang tight! We're finalizing your subscription. Your premium content will be unlocked once the page reloads.", "process");
    }  else {
      const searchParams = new URLSearchParams(window.location.search);
      console.log('searchParams', searchParams);
      if (searchParams.has('subscription-purchased')) {
        console.log('subscription purchased');
        showNotice("Welcome aboard! Your subscription is now active.", "success");
      } else {
        document.getElementById('notices').innerHTML = '';
      }
    }
  }
}

window.inAppWrapper = function() {
  return document.cookie.match("mobile_platform");
}

window.mobilePlatform = function() {
  return docCookies.getItem('mobile_platform');
}
// if (document.readyState !== 'loading') {
//   console.log('document is already ready, just execute code here');
//   loadAuthUi();
// } else {
//   document.addEventListener('DOMContentLoaded', loadAuthUi);
// }

jQuery(function() {
  // console.log('window.location.pathname', window.location.pathname);
  checkUserLoggedIn();
  if(window.location.pathname == '/sign-in/'){
    console.log('jQuery ready event, call load auth ui');
    // loadAuthUi();
  }
  showConversionBanner();
  showSubscriptionStatusAlert();
});

// document.addEventListener('DOMContentLoaded', showConversionBanner);
// document.addEventListener('DOMContentLoaded', showSubscriptionStatusAlert);
