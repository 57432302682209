//#conditional
let firebaseConfig;
console.log('Production!')
firebaseConfig = {
  apiKey: "AIzaSyDoJDXwAsyldAv4-9pJE4Ci7vR4GEvfmQM",
  authDomain: "the-food-medic.firebaseapp.com",
  projectId: "the-food-medic",
  storageBucket: "the-food-medic.appspot.com",
  messagingSenderId: "241774895725",
  appId: "1:241774895725:web:612c1e247ae8a840401564",
  measurementId: "G-G70SKH1BSP"
};
export { firebaseConfig };