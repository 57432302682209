import '../vendor/jquery';

export function startSpinner() {
	showSpinner(true);
}

export function stopSpinner() {
	showSpinner(false);
}

function showSpinner(show) {
	const body = $('body');
	const spinner = $('.activity-indicator');
	if (show) {
		spinner.removeClass('hidden');
		body.addClass('indicating-activity');
	} else {
		spinner.addClass('hidden');
		body.removeClass('indicating-activity');
	}
}
