import * as firebase from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from './config';
// import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
// // TODO: Replace the following with your app's Firebase project configuration
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);