// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { app } from './utils/firebase';
import { auth } from "../auth";
import { Controller } from "@hotwired/stimulus";
import { initTabs } from "../ui/tabs";
import _ from "lodash";
import { startSpinner, stopSpinner } from "../ui/spinner";

export default class extends Controller {
  static targets = ['stripeSubscription', 'revenuecatSubscription', 'monthly', 'annual', 'annualSubscribeBtn', 'annualSubscribed', 'annualUpgradeBtn', 'monthlySubscribeBtn', 'monthlySubscribed', 'monthlyDowngradeBtn', 
    'legacyNotice', 'basicPlans', 'plusPlans', 'diffPlatformSubscription', 'planChangeDisallowed'];

  initialize(){
    console.log('subscription controller');
    const that = this;
    
    auth.authStateReady().then(function(){
      if(auth.currentUser){
        console.log('auth ready current user found, load subscription data');
        startSpinner();
        that.getSubscriptionData();
        stopSpinner();
      } else {
        console.log('auth ready current user not found');
        that.subscriptionDetails = {};
        if(inAppWrapper()){
          that.stripeSubscriptionTarget.remove();
          that.revenuecatSubscriptionTarget.classList.remove('hidden');
        } else {
          that.revenuecatSubscriptionTarget.remove();
          that.stripeSubscriptionTarget.classList.remove('hidden');
        }
      }
    })
    
    // console.log("cookies.get('subscriptionProcessing')", cookies.get('subscriptionProcessing'));
    if(cookies.get('subscriptionProcessing')){
      // console.log('cookie exists')
      this.monthlySubscribeBtnTarget.remove();
      this.annualSubscribeBtnTarget.remove();
      this.annualUpgradeBtnTarget.remove();
      this.monthlyDowngradeBtnTarget.remove();
    }
  }

  connect() {
    initTabs();
  }
  
  async checkout(event) {
    startSpinner();
    event.preventDefault();
    if(!auth.currentUser){
      window.location = '/sign-in'
    }
    const parentNode = event.currentTarget.parentNode;
    console.log('event', parentNode.dataset);
    const price_id = parentNode.dataset.stripePrice;
    console.log('price_id', price_id);
    fetch(`/price/${price_id}/subscribe`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then((res) => res.json())
    .then((json) => {
      setTimeout(() => {
        stopSpinner();
        this.hideConversionBanner();
        window.location = json.url;
      }, 300)
    });
  }

  async appCheckout(event) {
    event.preventDefault();
    if(!auth.currentUser){
      window.location = '/sign-in'
    }
    const parentNode = event.currentTarget.parentNode;
    console.log('event', parentNode.dataset);
    const price_id = parentNode.dataset.revenuecatPrice;
    console.log('price_id', price_id);
    let payload = {
      action: "purchase",
      productID: price_id, // "tfm.monthly" or "tfm.annual"
      userID: auth.currentUser.uid, // supply proper ID
      email: auth.currentUser.email
    };
  
    let handler = window?.webkit?.messageHandlers?.webMessageHandler;
    if (handler) {
      handler.postMessage(JSON.stringify(payload));
      // alert("Sent " + JSON.stringify(payload) + ".");
    } else {
      alert("Could not process request. try again")
    }
  }

  async changePlan(event) {
    startSpinner();
    event.preventDefault();
    const parentNode = event.currentTarget.parentNode;
    console.log('event', parentNode.dataset);
    const price_id = parentNode.dataset.stripePrice;
    console.log('price_id', price_id);
    fetch(`/price/${price_id}/change`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then((res) => res.json())
    .then((json) => {
      console.log('json', json);
      setTimeout(() => {
        stopSpinner();
        window.location = json.url;
      }, 300)
    })
    .catch((error) => {
      stopSpinner();
      console.log('error', error);
    });
  }

  async getSubscriptionData(){
    const that = this;
    const res = await fetch('/subscription/details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    })
    if(res.status == 401){
			console.info('401 unauthorized get subscriptionData from home page', auth.currentUser);
			App.logout();
			return;
		}
    const data = await res.json();
    if(_.isEmpty(data)){
      this.plusPlansTarget.remove();
      this.hideConversionBanner();
      if(inAppWrapper()){
        that.stripeSubscriptionTarget.remove();
        that.revenuecatSubscriptionTarget.classList.remove('hidden');
      } else {
        that.revenuecatSubscriptionTarget.remove();
        that.stripeSubscriptionTarget.classList.remove('hidden');
      }
      return false;
    }
    // console.log('data', data);
    this.subscriptionDetails = data;
    this.toggleConversionBanner(data);
    const price_id = data.price_id;
    if(price_id){
      that.revenuecatSubscriptionTarget.remove();
      that.stripeSubscriptionTarget.classList.remove('hidden');
      if(data.status == 'paused'){
        this.monthlySubscribeBtnTarget.remove();
        this.annualSubscribeBtnTarget.remove();
        this.annualUpgradeBtnTarget.remove();
        this.monthlySubscribeBtnTarget.remove();
      }
      else {
        cookies.erase('subscriptionPaused');        
      }
      if(data.plus_membership){
        this.basicPlansTarget.remove();
        this.plusPlansTarget.classList.remove('hidden');
      } else {
        this.plusPlansTarget.remove();
      }
      if(this.monthlyTarget.dataset.stripePrice == price_id) {
        this.monthlySubscribeBtnTarget.classList.add('hidden');
        this.monthlySubscribedTarget.classList.remove('hidden');
        this.annualSubscribeBtnTarget.classList.add('hidden');
        this.annualUpgradeBtnTarget.classList.remove('hidden');
        if(inAppWrapper()){
          this.annualUpgradeBtnTarget.setAttribute('disabled', true);
        }
      } else {
        this.monthlySubscribeBtnTarget.classList.add('hidden');
        this.monthlySubscribedTarget.classList.add('hidden');
        this.annualSubscribeBtnTarget.classList.add('hidden');
        this.annualUpgradeBtnTarget.classList.add('hidden');
        this.annualSubscribedTarget.classList.remove('hidden');
        this.monthlyDowngradeBtnTarget.classList.remove('hidden');
        if(inAppWrapper()){
          this.monthlyDowngradeBtnTarget.setAttribute('disabled', true);
        }
      }
      this.hideConversionBanner();
      if(inAppWrapper()){
        this.diffPlatformSubscriptionTarget.classList.remove('hidden');
      }
    }
    else if(data.product){
      // todo show when subscription from app through revenuecat
      that.stripeSubscriptionTarget.remove();
      that.revenuecatSubscriptionTarget.classList.remove('hidden');
      if(this.monthlyTarget.dataset.revenuecatPrice == data.product) {
        this.monthlySubscribeBtnTarget.classList.add('hidden');
        this.monthlySubscribedTarget.classList.remove('hidden');
        this.annualSubscribeBtnTarget.setAttribute('disabled', true);
      } else {
        this.monthlySubscribeBtnTarget.setAttribute('disabled', true);
        // this.monthlySubscribedTarget.classList.add('hidden');
        this.annualSubscribeBtnTarget.classList.add('hidden');
        this.annualSubscribedTarget.classList.remove('hidden');
      }
      if(!inAppWrapper()){
        this.diffPlatformSubscriptionTarget.classList.remove('hidden');
      }
      that.planChangeDisallowedTarget.classList.remove('hidden');
      console.log('mobile platform', mobilePlatform());
    } 
    else {
      that.revenuecatSubscriptionTarget.remove();
      that.stripeSubscriptionTarget.classList.remove('hidden');
      if(data.plus_membership){
        this.basicPlansTarget.remove();
        this.plusPlansTarget.classList.remove('hidden');
      } else {
        this.legacyNoticeTarget.classList.remove('hidden');
        this.monthlySubscribeBtnTarget.classList.add('hidden');
        this.annualSubscribeBtnTarget.classList.add('hidden');
        this.hideConversionBanner();
      }
    }
  }

  toggleConversionBanner(data){
    if(data.legacy && data.plus_membership) {
      cookies.set('legacyPlus', true);
      document.getElementById('plus-conversion-banner').classList.remove('hidden');
    } else {
      cookies.erase('legacyPlus');
      document.getElementById('plus-conversion-banner').classList.add('hidden');
    }
  }

  hideConversionBanner(){
    cookies.erase('legacyPlus');
    document.getElementById('plus-conversion-banner').classList.add('hidden');
  }
}