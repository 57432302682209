import '../vendor/jquery';
import { PlyrLayout, VidstackPlayer } from 'vidstack/global/player';
import 'vidstack/player/styles/base.css';
import 'vidstack/player/styles/plyr/theme.css';

export async function initVideoPlayer(target='.video-player') {
	let id = $(target).data('video-id');
	let title = $(target).data('video-title');
	
	const player = await VidstackPlayer.create({
		target: target,
		title: title,
		src: 'https://vod.api.video/vod/' + id + '/hls/manifest.m3u8',
		poster: 'https://vod.api.video/vod/' + id + '/thumbnail.jpg',
		layout: new PlyrLayout()
	});
}

$( document ).ready(function() {
	if ($('.video-player').length) {
		initVideoPlayer();
	}
});
