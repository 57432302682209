import { auth } from "../auth";
import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import { Timestamp } from "firebase/firestore";

export default class extends Controller {
	static targets = ["activeProgramContainer", "activeProgramPlaceholderContainer", "latestProgramContainer", "loadingPlaceholder"]

  connect() {
    
  }

  initialize() {
    // const that = this;
    // auth.authStateReady().then(function(){
    // })
		const that = this;
    auth.authStateReady().then(function(){
      const user = auth.currentUser;
      // console.log('user name', user.displayName);
			let name = user ? user.displayName : '';
			that.populateGreeting(name);
			if (user){
				that.getProgramData();
				that.getSubscriptionData();
			}
    })
  }
	
	latestProgramContainerTargetConnected() {
		const that = this;
		auth.authStateReady().then(function(){
			const user = auth.currentUser;
			if(!user){
				that.latestProgramContainerTarget.classList.remove('hidden');
				that.loadingPlaceholderTarget.classList.add('hidden');
			}
		})
	}

	populateGreeting(fullName) {
		// Greeting
		const heading = $("#home-heading");
		const title = heading.find("h1");
		const description = heading.find("p");
		
		if (heading.length) {
  		const now = new Date();
  		const hours = now.getHours();
		
			// Title
			var greeting;
  		if (hours >= 5 && hours < 12) {
    		greeting = "Good morning";
  		} else if (hours >= 12 && hours < 15) {
    		greeting = "Good afternoon";
  		} else if (hours >= 15 && hours < 18) {
    		greeting = "Good evening";
  		} else {
    		greeting = "Good night";
  		}
			
			if (fullName) {
				greeting += ", ";
	    	let nameWithoutTitle = fullName.replace(/^Dr\.?\s+/, '');
    		let nameParts = nameWithoutTitle.split(' ');
				greeting += nameParts[0];
			}
			greeting += "!";
			
			title.text(greeting);
			
			// Subtitle
  		const options = { weekday: 'long', month: 'long', day: 'numeric' };
  		const today = new Date();
			const formattedDate = today.toLocaleDateString('en-US', options);
			var text = description.text();
			text = text.replace("[today]", formattedDate);
			description.text(text);
			
			// Show the header
			heading.addClass("loaded");
		}
	}

	async getProgramData(){
		const res = await fetch('/fitness/program', {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'accept': 'application/json'
			}
		})
		if(res.status == 401){
			console.info('401 unauthorized get programData', auth.currentUser);
			App.logout();
			return;
		}
		const data = await res.json();
		// console.log('program data', data);
		this.programData = data;
		this.loadingPlaceholderTarget.classList.add('hidden');
		if(_.isEmpty(data)){
			this.latestProgramContainerTarget.classList.remove('hidden');
      return false;
    }
		if(data.price_id && data.status !== 'paused'){
			cookies.erase('subscriptionPaused');
			document.getElementById('notices').innerHTML = '';
		}
		const endDate = this.getProgramEndDate(data);
		if(endDate <= new Date()){
			this.latestProgramContainerTarget.classList.remove('hidden');
			return;
		}
		this.latestProgramContainerTarget.classList.add('hidden');
		const weekData = data.weeks[0];
		const currentDay = new Date().getDay();
		// const currentDay = 2;
		// console.log('currentDay', currentDay);
		// console.log('workout for day', weekData[currentDay]);
		const workoutData = weekData[currentDay];
		if(workoutData == undefined) {
			this.activeProgramPlaceholderContainerTarget.classList.remove('hidden');
			this.activeProgramContainerTarget.classList.add('hidden');
			// "activeProgramContainer", "activeProgramPlaceholderContainer"
		} else {
			this.activeProgramPlaceholderContainerTarget.classList.add('hidden');
			this.activeProgramContainerTarget.classList.remove('hidden');
			const currentWeekIndex = this.getCurrentWeekIndex(data);
			const workoutDayIndex = this.getWorkoutDay();
			// console.log('workoutDayIndex', workoutDayIndex);
			// console.log('current week', currentWeekIndex);
			document.getElementById('active-programme-name').innerText = data.title;
			const imageElem = document.getElementById('active-program-image');
			// console.log('imageElem', imageElem);
			imageElem.src = data.image;
			document.getElementById('active-programme-workout').innerText = workoutData.title;
			document.getElementById('active-programme-week-day').innerText = `Week ${currentWeekIndex + 1}, Day ${workoutDayIndex + 1}`;
			document.getElementById('workoutUrl').href = data.url + `?w=${workoutData.id}&week=${currentWeekIndex}`;
			const progress = this.getProgress(currentWeekIndex, workoutDayIndex, parseInt(data.week_repetition), Object.keys(weekData).length);
			document.getElementById('active-programme-progress').style.width = `${progress}%`
		}
	}

	async getSubscriptionData(){
    const res = await fetch('/subscription/details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    })
		if(res.status == 401){
			console.info('401 unauthorized get subscriptionData from home page', auth.currentUser);
			App.logout();
			return;
		}
		const data = await res.json();
		if(_.isEmpty(data)){
			this.toggleConversionBanner(data);
			return false;
		}
		// console.log('data', data);
		const lockedIcons = document.querySelectorAll('.locked-content');
		lockedIcons.forEach(element => {
			element.classList.add('hidden');
		});;
		this.toggleConversionBanner(data);
  }

	getCurrentWeekIndex(data) {
		const enrollmentDate = this.getEnrollmentDate(data);
    // console.log('enrollment date 0', enrollment_date);
    // const dayOfEnrollment =  enrollment_date.getDay();
		let weekIndex = 0;
		enrollmentDate.setDate(enrollmentDate.getDate() + 7)
		// console.log("new Date() < enrollment_date", new Date() <= enrollment_date, new Date());
    weekIndex =  this.getWeekIndex(enrollmentDate, weekIndex);
		// console.log('week index',weekIndex);
		return weekIndex;
	}

	getEnrollmentDate(data) {
		const enrollmentTimestamp = data.subscribed_at;
		const enrollmentDate = new Timestamp(enrollmentTimestamp._seconds, enrollmentTimestamp._nanoseconds).toDate();
    // console.log('enrollment date', enrollmentDate);
		return enrollmentDate;
	}

	getProgramEndDate(data){
		const enrollmentDate = this.getEnrollmentDate(data);
		// console.log('enrollmentDate', enrollmentDate);
		let weekRepetition = parseInt(data.week_repetition);
    // console.log('enrollment day', firstWorkoutDay,  enrollmentDate.getDay());
		// console.log('weekRepetition', weekRepetition);
    // const startWeekMonday = this.getMonday(enrollmentDate);
		let endDate = new Date(enrollmentDate);
		endDate.setDate(enrollmentDate.getDate() + (weekRepetition * 7));
		// console.log('endDate', endDate);
		// console.log('endDate monday', endDate);
		return endDate;
	}

  getFirstWorkoutDay(data){
    const workoutDays = Object.keys(data.weeks[0]);
    // console.log('workoutDays', workoutDays);
    return parseInt(workoutDays[0]);
  }

  getWeekIndex(enrollment_date, weekIndex){
		// console.log('new Date', new Date());
		// console.log('enrollment_date Date', enrollment_date);
		// console.log("date compare", new Date() < enrollment_date);
    if(new Date() < enrollment_date) {
			// console.log('if enrollment date', enrollment_date, weekIndex);
			// console.log('returning weekIndex', weekIndex);
			return weekIndex;
    } else {
			weekIndex += 1;
			enrollment_date.setDate(enrollment_date.getDate() + 7)
			// console.log('else enrollment date', enrollment_date, weekIndex);
			return this.getWeekIndex(enrollment_date, weekIndex);
		}
  }

	getWorkoutDay(){
		const workoutDayForUserWeek = this.getWorkoutDaysForUserWeekInOrder();
    const workoutIndex = workoutDayForUserWeek.findIndex(day => day == new Date().getDay());
		return workoutIndex;
	}

	getWorkoutDaysForUserWeekInOrder(){
    const enrollmentDay = this.getEnrollmentDay();
    const workoutDays = this.getWorkoutDays();
    // console.log('workout days', workoutDays, enrollmentDay);
    let days = [];
    for(let i= 0; i< workoutDays.length; i++){
      const day = parseInt(workoutDays[i]);
      if(day >= enrollmentDay){
        days.push(day);
      }
    }
    for(let i= 0; i< workoutDays.length; i++){
      const day = parseInt(workoutDays[i]);
      if(day < enrollmentDay){
        days.push(day);
      }
    }
    return days;
  }

	getEnrollmentDay(){
    const enrollmentTimestamp = this.programData.subscribed_at;
    const enrollmentDate = new Timestamp(enrollmentTimestamp._seconds, enrollmentTimestamp._nanoseconds).toDate();
    const dayOfEnrollment =  enrollmentDate.getDay()
    return dayOfEnrollment;
  }

  getWorkoutDays(){
    return Object.keys(this.programData.weeks[0]);
  }

	getProgress(currentWeekIndex, currentDayIndex, totalWeeks, workoutPerWeek){
		const totalExercises = totalWeeks * workoutPerWeek;
		const completedExercises = (currentWeekIndex * workoutPerWeek) + currentDayIndex;
		// console.log('currentWeekIndex', currentWeekIndex);
		// console.log('workoutPerWeek', workoutPerWeek);
		// console.log('currentDayIndex', currentDayIndex);
		// console.log('totalExercises', totalExercises);
		// console.log('completedExercises', completedExercises);
		// console.log('completedExercises/totalExercises', completedExercises/totalExercises);
		return (completedExercises/totalExercises)*100;
	}

	getNextMonday(date) {
    let today;
    if(date){
      today = date;
    } else {
      today = new Date();
    }
    const dayOfWeek = today.getDay();
    let daysUntilMonday = (1 - dayOfWeek + 7) % 7;
    if (daysUntilMonday === 0) {
        return today; // Return today if it's Monday
    }
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilMonday);
    return nextMonday;
	}

	getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

	toggleConversionBanner(data={}){
		if(data && data.legacy && data.plus_membership) {
			cookies.set('legacyPlus', true);
			document.getElementById('plus-conversion-banner').classList.remove('hidden');
		} else {
			cookies.erase('legacyPlus');
			document.getElementById('plus-conversion-banner').classList.add('hidden');
		}
	}
}