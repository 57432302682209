import '../vendor/jquery';

$( document ).ready(function() {
	
	let searchParams = new URLSearchParams(window.location.search)
	
	if (searchParams.has('webinar-purchased')) {
		showNotice("You're all set! Webinar purchased successfully.", "success");
	}
  
});

window.showNotice = function (text, style) {
	let target = $('#notices');
	
	let notice_classes = "flex items-center gap-5 md:w-fit md:max-w-2xl md:mx-auto mb-3.5 mx-5 px-5 py-4 text-subtext rounded-normal ";
	let notice_wrapper = $('<div/>');
	let notice_icon = $('<i/>').addClass('flex-none fal');
	let notice_text = $('<div/>').text(text).addClass('flex-1 transform-y-px');
	
	if (style == 'success') {
		notice_icon.addClass('fa-circle-check');
		notice_classes += "bg-green-100 text-green-900"
		
	} else if (style == 'fail') {
		notice_icon.addClass('fa-circle-exclamation');
		notice_classes += "bg-red-100 text-red-900 border border-red-200"
		
	} else if (style == 'process') {
		notice_icon.addClass('fa-circle-notch').addClass('fa-spin');
		notice_classes += "bg-blue-100 text-blue-900 border border-blue-200"
		
	} else {
		notice_icon.addClass('fa-circle-info');
		notice_classes += "bg-surface-secondary text-gray-900"
	}
	
	notice_wrapper.addClass(notice_classes).html(notice_icon).append(notice_text);
	target.append(notice_wrapper);
}