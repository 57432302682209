import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	
  connect() {
  }

  initialize() {
		this.showConcludedHeading();
  }
	
	showConcludedHeading() {
		const upcomingHeader = $("#webinar-upcoming-header");
		const concludedHeader = $("#webinar-concluded-header");
		const webinarEnd = $("[data-webinar-end]").data("webinar-end");
		
  	const now = new Date();
  	const dateToCheck = new Date(webinarEnd);
		
  	if (now > dateToCheck) {
			upcomingHeader.addClass('hidden');
			concludedHeader.removeClass('hidden');
			// console.log("after")
		} else {
			// console.log("before")
		}
	}

}
