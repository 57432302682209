import '../vendor/jquery';

export function startTimer(button) {
	let id = timerId(button)
	showTimerControl(id, "timer-active");
	createTimer(id); 
	getTimer(id).start(id);
}

export function pauseTimer(button) {
	let id = timerId(button)
	showTimerControl(id, "timer-paused");
	getTimer(id).pause();
}

export function setLap(button) {
	let id = timerId(button);
	console.log('timerId', id);
	let records = $(".exercise[data-id='" + id + "'] .timer-records");
	if (records.hasClass("hidden")) {
		records.removeClass("hidden");
	}
	
	let order = records.children().length + 1;
	let time = $("button#play-" + id + " span").text();
	
	let record = $( $("template.time-record").html() );
	record.find(".order").text(order);
	record.find(".time").text(time);
	
	records.append(record);
}

export function resumeTimer(button) {
	let id = timerId(button)
	showTimerControl(id, "timer-active");
	getTimer(id).start();
}

export function resetTimer(button) {
	let id = timerId(button)
	showTimerControl(id, "timer-init");
	getTimer(id).reset(id);
	$(".exercise[data-id='" + id + "'] .timer-records").html("").addClass("hidden");
}

class Timer {
  constructor(id) {
    this.id = id
    this.seconds = 0;
    this.intervalId = null;
  }

  formatTime() {
    const minutes = String(Math.floor(this.seconds / 60)).padStart(2, '0');
    const secs = String(this.seconds % 60).padStart(2, '0');
    return `${minutes}:${secs}`;
  }

  // Start the timer
  start() {
    if (this.intervalId) return; // prevent multiple intervals
    this.intervalId = setInterval(() => {
      this.seconds++;
      setButtonTime(this.id, this.formatTime());
    }, 1000);
    console.log('this', this);
  }

  // Pause the timer
  pause() {
    clearInterval(this.intervalId);
    this.intervalId = null;
  }

  // Reset the timer
  reset(id) {
    this.pause();
    this.seconds = 0;
		setButtonTime(id, this.formatTime());
  }
}

const timers = {};

function createTimer(id) {
  if (timers[id]) {
    console.error(`Timer with ID '${id}' already exists.`);
    return;
  }
  timers[id] = new Timer(id);
  console.log(`Timer with ID '${id}' created.`);
}

function getTimer(id) {
  return timers[id];
}

function timerId(element) {
  return $(element).closest('[data-id]').data('id');
}

function showTimerControl(id, className) {
	let hiddenClass = "!hidden";
	let timerControls = $("#exercise-" + id +" .timer-controls");
	timerControls.find("> div").addClass(hiddenClass);
	timerControls.find("." + className).removeClass(hiddenClass);
}

function setButtonTime(id, timeString) {
	$("button#play-" + id + " span").text( timeString );
	$("button#pause-" + id + " span").text( timeString );
}
