//#conditional
import { getAuth, EmailAuthProvider, connectAuthEmulator, updateProfile, updateEmail, sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { app } from './utils/firebase';


const auth = getAuth(app);
// export async function loadAuthUi(){
//   const elem = document.getElementById('auth-container');
//   console.log('load auth ui on signin page', elem);
//   const uiConfig = {
//     callbacks: {
//       signInSuccessWithAuthResult: function(authResult, redirectUrl) {
//         const user = authResult.user;
//         // console.log('user', user);
//         if (authResult.additionalUserInfo.isNewUser) {
//           sendEmailVerification(user).then(() => {
//             console.log('verification email sent');
//           });
//         }
//         var dt = new Date();
//         var timeout = 55*60*1000;
//         // console.log('authResult', authResult);
//         // document.cookie = "firebaseAccessToken=" + user.accessToken + '; path=/; expires='+ dt.getTime + 60*60*1000;
//         cookies.set("firebaseAccessToken", user.accessToken, 365);
//         cookies.set("firebaseAccessTimer", dt.getTime() + timeout, 365);
//         // document.cookie = "firebaseAccessTimer=" + (dt.getTime() + timeout) + '; path=/';
//         // console.log('after signin', authResult);
//         // console.log('redirecturl', redirectUrl);
//         window.location = '/';
//         setUiToLoggedIn();
//       },
//       signInFailure: function(error) {
//         setUiToLoggedOut();
//         // console.log('error', error);
//       }
//     },
//     signInOptions: [
//       EmailAuthProvider.PROVIDER_ID
//     ],
//     signInSuccessUrl: '/'
//     // signInSuccessUrl: '/',
//     // Other config options...
//   }
//   if(elem){
//     try{
//       const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
//       ui.start('#auth-container', uiConfig);
//       $(".signin-loading").removeClass("signin-loading");
//     } catch(e) {
//       console.log('load auth ui catch error', e);
//       $(".signin-loading").addClass("signin-error").removeClass("signin-loading");
//     }
//   } else {
//     $(".signin-loading").addClass("signin-error").removeClass("signin-loading");
//   }
// }

export function logout() {
  auth.signOut().then(function() {
    // console.log('Signed Out');
    fetch('/logout', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then(function(res){
      // console.log('res', res);
      cookies.erase('firebaseAccessTimer');
      cookies.erase('firebaseAccessToken');
      setUiToLoggedOut();
      hideConversionBanner();
      window.location ='/sign-in';
    })
  }, function(error) {
    console.error('Sign Out Error', error);
  });
}

jQuery(function(){
  auth.authStateReady().then(function(){
    // console.log('auth state ready current user', auth.currentUser )
    const user = auth.currentUser;
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // const uid = user.uid;
      // console.log('user', user);
      // ...
      // console.log('auth.currentUser get id token');
      // user.getIdTokenResult().then(function(res){
      //   console.log('res', res);
      // })
    var refresh = false;
    (function poll(){
      var dt = new Date();
      var timeout = 55*60*1000;
      if (docCookies.getItem('firebaseAccessTimer') - dt.getTime() <= 0) {
        refresh = true;
      }
      console.log('refresh', refresh);
      // console.log("docCookies.getItem('firebaseAccessTimer')", docCookies.getItem('firebaseAccessTimer'), dt.getTime(), docCookies.getItem('firebaseAccessTimer') - dt.getTime());
      if (user) {
        user.getIdToken(refresh).then(function(accessToken) {
          // console.info("accessToken === docCookies.getItem('firebaseAccessToken')", accessToken, docCookies.getItem('firebaseAccessToken'), accessToken === docCookies.getItem('firebaseAccessToken'), user.uid, user.email);
          if (accessToken === docCookies.getItem('firebaseAccessToken')) {
            window.setTimeout(poll, docCookies.getItem('firebaseAccessTimer') - dt.getTime());
          } else {
            // console.log('refresh token for', user.uid)
            cookies.set("firebaseAccessToken", user.accessToken, 365);
            cookies.set("firebaseAccessTimer", dt.getTime() + timeout, 365);
            window.setTimeout(poll, timeout);
          }
        });
        setUiToLoggedIn();
      } else {
        // console.log('user signed-out');
        cookies.erase('firebaseAccessToken');
        cookies.erase('firebaseAccessTimer');
        // console.log('location.pathname', location.pathname);
        // if(location.pathname !== '/sign-in/'){
        hideConversionBanner();
          // location.href = '/sign-in';
        // }
        if(window.location.pathname == '/sign-in/'){
          // loadAuthUi();
        }
        setUiToLoggedOut();
      }
    })();
  })

  // auth.onIdTokenChanged().
})

const docCookies = {
  getItem: function (sKey) {
    if (!sKey || !this.hasItem(sKey)) { return null; }
    return unescape(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
  },
  hasItem: function (sKey) {
    return (new RegExp("(?:^|;\\s*)" + escape(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  }
};

const checkUserLoggedIn = function(){
  auth.authStateReady().then(function(){
    // console.log('auth state ready current user', auth.currentUser )
    const user = auth.currentUser;
    const accessToken = docCookies.getItem('firebaseAccessToken');
    if(user && accessToken && window.location.pathname == '/sign-in/') {
      window.location = '/';
    }
  })
}

export {auth, updateProfile, updateEmail, sendPasswordResetEmail, checkUserLoggedIn, docCookies};