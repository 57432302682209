// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { app } from './utils/firebase';
import { auth } from "../auth";
import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import { Timestamp } from "firebase/firestore";

export default class extends Controller {
  static targets = [];

  connect() {
    console.log('activity conroller connected');
  }

  initialize(){
    const that = this;
    auth.authStateReady().then(async function(){
			const user = auth.currentUser;
      if(!user){
        window.location = '/sign-in'
      }
      await that.getActivitiesData();
			if(!_.isEmpty(that.activitiesData)){
				that.populateActivities();
			} else {
				that.showBlankSlateIfNeeded();
			}
			that.hideSpinner();
    })
  }

  async getActivitiesData(){
    const res = await fetch('/activities',{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    })
    const data = await res.json();
    this.programName = data.programName;
    this.activitiesData = data.activities;
    console.log('data', this.activitiesData);
		
  }
	
	hideSpinner() {
		$("#activity-loading").remove();
	}
	
	showBlankSlateIfNeeded() {
		// if (this.activitiesData.length === 0) {
		$("#activity-blank-slate").removeClass("hidden");
		// }
	}

  populateActivities() {
		const that = this;
		
    let dayTemplate = $( $("template#activity-day").html() );
		let exerciseTemplate = $( $("template#activity-exercise").html() );
		let recordTemplate = $( $("template#activity-record").html() );
		
		// Days
		for (let i=0; i < that.activitiesData.length; i++) {
      const data = that.activitiesData[i];
      console.log('data', data);

			let dayElement = dayTemplate.clone();
			const date = data.date;
			let dateId = new Timestamp(date._seconds, date._nanoseconds).toDate();
			dayElement.find("button[data-toggle-animated]").attr("data-toggle-animated", "#activity-"+data.id);
			dayElement.find(".exercises").attr("id", "activity-"+data.id);
			
  		let options = { year: 'numeric', month: 'long', day: 'numeric' };
			let dateString = dateId.toLocaleDateString('en-US', options);
			dayElement.find(".date").text(dateString);
			
			let dayTitle = data.workoutTitle + " / " + that.programName
			dayElement.find(".workout-programme-name").text(dayTitle);
			
			var exercises = data.logs.length + " ";
			exercises += data.logs.length == 1 ? "exercise" : "exercises";
			dayElement.find(".aux").text(exercises);
			
			$("#activity-history-list").append(dayElement);
			
			// Exercises
			for (const exercise of data.logs) {
				let exerciseElement = exerciseTemplate.clone();
				exerciseElement.find(".exercise-name").text(exercise.title);
				dayElement.find(".exercises .grid").append(exerciseElement);
				
				// Sets
				for (const set of exercise.sets) {
					let recordElement = recordTemplate.clone();
					
					// Lifts
					if (set.lift && set.lift.length > 0) {
						recordElement.find(".lift").text(set.lift + " kg");
					} else {
						recordElement.find(".lift").parent().remove();
					}
					
					// Reps
					if (set.reps && set.reps.length>0) {
						recordElement.find(".reps").text(set.reps + " reps");
					} else {
						recordElement.find(".reps").parent().remove();
					}
					
					// Time
					if (set.time && set.time.length>0) {
						recordElement.find(".time").text(set.time + " seconds");
					} else {
						recordElement.find(".time").parent().remove();
					}

					// RPE
					if (set.rpe && set.rpe.length>0) {
						recordElement.find(".rpe").text(set.rpe + " RPE");
					} else {
						recordElement.find(".rpe").parent().remove();
					}
					
					// RIR
					if (set.rir && set.rir.length>0) {
						recordElement.find(".rir").text(set.rir + " RIR");
					} else {
						recordElement.find(".rir").parent().remove();
					}
					
					exerciseElement.find(".records").append(recordElement);
				}
			}
		}
		
		App.initUnhides(); // make sure bindings are set once UI is generated
	}
}