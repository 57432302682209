// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { app } from './utils/firebase';
import { auth } from "../auth";
import { Controller } from "@hotwired/stimulus"
import { serializeForm } from "../helpers/templates"
import { startSpinner, stopSpinner } from "../ui/spinner";
import { initVideoPlayer } from "../ui/video-player";
import { initTabs } from "../ui/tabs";
import { Timestamp } from "firebase/firestore";

export default class extends Controller {
  static targets = ["subscribeProgramBtn", "exerciseView", "workoutView", "programOverview", "progress", "exerciseDetail", "exerciseInputRowTemplate"];

  connect() {
    console.log('fitness conroller connected');
    initTabs();
  }

  initialize(){
    this.programData = {};
    this.userWeek = 0;
    this.exercisesWithActivityLoaded = []
  }

  subscribeProgramBtnTargetConnected(){
    console.log('subscribe program button connected');
    const that = this;
    const searchParams = new URLSearchParams(window.location.search);
    const workoutId = searchParams.get('w');
    const userWeek = parseInt(searchParams.get('week')) || 0;
    this.userWeek = userWeek
    auth.authStateReady().then(function(){
      that.getProgramData(workoutId);
    })
  }

  programOverviewTargetConnected(){
    console.log('program overview target connected');
    const searchParams = new URLSearchParams(window.location.search);
    const workoutId = searchParams.get('w');
    if(!workoutId){
      return
    }
    this.programOverviewTarget.classList.add('hidden');
    document.getElementById("page-heading").classList.add('hidden');
    if(window.location.href.match(/\/locked/)){
      console.info('matched locked')
      this.programOverviewTarget.classList.remove('hidden');
      document.getElementById("page-heading").classList.remove('hidden');
      return;
    }
  }
  workoutViewTargetConnected(){
    console.log('workout view connected');
  }

  exerciseViewTargetConnected(){
    console.log('exercise view connected');
  }
  async subscribe(event) {
    startSpinner();
    event.preventDefault();
    const parentNode = event.currentTarget.parentNode;
    const programId = parentNode.dataset.programId;
    console.log('programId', programId);
    fetch(`/fitness/${programId}/subscribe`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then((res) => res.json())
    .then((json) => { 
      stopSpinner();
      window.location = '/training-overview';
    });
  }

  async getProgramData(workoutId){
    if(workoutId){
      startSpinner();
    }
    const res = await fetch('/fitness/program', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    })
    if(res.status == 401){
			console.info('401 unauthorized get subscriptionData from fitness page', auth.currentUser);
			App.logout();
			return;
		}
    const data = await res.json();
    // console.log('data', data);
    this.programData = data;
    if(!data.id) {
      stopSpinner();
      return false;
    }
    const programId = this.subscribeProgramBtnTarget.parentNode.dataset.programId;
    if(data && data['id'] == programId){
      this.subscribeProgramBtnTarget.parentNode.classList.add('hidden');
    }
    if(workoutId) {
      this.renderWorkout(workoutId);
      return false;
    } else {
      stopSpinner();
      this.programOverviewTarget.classList.remove('hidden');
    }
  }

  renderWorkout(workoutId){
    this.workoutId = workoutId;
    // console.log('this.programData.week_repetition', this.programData.week_repetition);
    const weekRepetition = parseInt(this.programData.week_repetition);
    if(this.userWeek >= weekRepetition){
      this.userWeek = 0;
    }
    // console.log('headerDay', headerDay);
    const workoutDayForUserWeek = this.getWorkoutDaysForUserWeekInOrder();
    let weekData = [];
    for(let i=0; i < workoutDayForUserWeek.length; i++){
      weekData.push(this.programData.weeks[0][workoutDayForUserWeek[i]])
    }
    // console.log('week data', weekData);
    const workoutIndex = weekData.findIndex(data => data.id == this.workoutId);
    // console.log('workoutDay for user week', workoutDayForUserWeek, workoutIndex);
    const headerDay = `Day ${workoutIndex+1}`;
    $(`#heading-${this.workoutId} .workout-day`).text(`Week ${this.userWeek+1}, ${headerDay}`);
    this.activityDataForUserWeek = []
    const activitiesData = this.programData.activities;
    // console.log('this.userWeek', this.userWeek);
    if(activitiesData) {
      const workoutActivites = activitiesData.find(activity => activity.workoutId == this.workoutId && activity.week == this.userWeek)
      if(workoutActivites){
        this.activityDataForUserWeek = workoutActivites.logs
      }
    }
    // console.log('this.activityDataForUserWeek', this.activityDataForUserWeek);
    this.workoutViewTarget.classList.remove('hidden');
    this.workoutViewTarget.querySelector(`[data-id='${workoutId}']`).classList.remove('hidden');
    this.programOverviewTarget.classList.add('hidden');
    stopSpinner();
  }

  getWorkoutDaysForUserWeekInOrder(){
    const enrollmentDay = this.getEnrollmentDay();
    const workoutDays = this.getWorkoutDays();
    console.log('workout days', workoutDays, enrollmentDay);
    let days = [];
    for(let i= 0; i< workoutDays.length; i++){
      const day = parseInt(workoutDays[i]);
      if(day >= enrollmentDay){
        days.push(day);
      }
    }
    for(let i= 0; i< workoutDays.length; i++){
      const day = parseInt(workoutDays[i]);
      if(day < enrollmentDay){
        days.push(day);
      }
    }
    return days;
  }

  getEnrollmentDay(){
    const enrollmentTimestamp = this.programData.subscribed_at;
    const enrollmentDate = new Timestamp(enrollmentTimestamp._seconds, enrollmentTimestamp._nanoseconds).toDate();
    const dayOfEnrollment =  enrollmentDate.getDay()
    return dayOfEnrollment;
  }

  getWorkoutDays(){
    return Object.keys(this.programData.weeks[0]);
  }

  beginWorkout(event){
    if(event){
      event.preventDefault();
    }
    // console.log('this.exercises', this.exercises);
    // console.log('this.program data', this.programData);
    startSpinner();
    this.currentExerciseIndex = 0;
    const divs = document.getElementsByClassName('exercise');
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add('hidden')
    }
    this.setExerciseAndTitle();
    // this.setProgress();
    if(document.querySelector(`#exercise-${this.exerciseId} .exercise-video`)){
      initVideoPlayer(`#exercise-${this.exerciseId} .exercise-video`);
    }
    this.loadExistingActivities();
    this.programOverviewTarget.classList.add('hidden');
    this.workoutViewTarget.classList.add('hidden');
    this.exerciseViewTarget.classList.remove('hidden');

    stopSpinner();
  }

  preview(event){
    // console.log('preview workout id', this.workoutId);
    // console.log('preview exercise id', this.exerciseId);
    // console.log('preview userweek', this.userWeek);
    event.preventDefault();
    const target = event.currentTarget;
    const dataset = target.dataset;
    this.workoutId = dataset.workoutId;
    this.renderWorkout(this.workoutId);
    this.exerciseId = dataset.exerciseId;
    if(this.exerciseId){
      this.openExercise();
    } else {
      this.beginWorkout();
    }
    // console.log('target dataset', target.dataset);
  }

  setExerciseAndTitle(){
    const workoutExerciseElem = document.getElementById(`workout-exercise-${this.workoutId}`);
    const exercises = workoutExerciseElem.getElementsByClassName('exercise');
    // console.log('exercises', exercises);
    const currentExercise = exercises[this.currentExerciseIndex];
    // console.log('current exercise', currentExercise);
    this.exerciseId = currentExercise.dataset.id;
    this.workoutTitle = workoutExerciseElem.dataset.title;
    this.exerciseTitle = currentExercise.dataset.title;
    // const exerciseId = this.exercises[this.currentExerciseIndex].id;
    currentExercise.classList.remove('hidden');
  }

  loadExistingActivities(){
    // console.log('this.programData activities', this.programData.activities);
    // console.log('this.activityDataForUserWeek', this.activityDataForUserWeek);
    if(this.activityDataForUserWeek){
      if(this.exercisesWithActivityLoaded.indexOf(this.exerciseId) > -1){
        return;
      }
      const activity = this.activityDataForUserWeek.find(data => data.exerciseId == this.exerciseId)
      let sets;
      if(!activity){
        return;
      }
      sets = activity.sets;
      const inputRowsContainer = document.getElementById(`input-rows-${this.exerciseId}`);
      const inputRows = inputRowsContainer.getElementsByClassName('input-row');
      if(sets.length > inputRows.length){
        const newInputRowCount = sets.length - inputRows.length;
        for(let i=0; i< newInputRowCount; i++){
          this.addInputRow();
        }
      }

      // console.log(`input-rows-${this.exerciseId}`);
      $(`#input-rows-${this.exerciseId} .input-row`).each(function(index, inputRow) {
        const rowElem = $(inputRow);
        const set = sets[index];
        // console.log('set', set);
        if(set !== undefined){
          console.log("rowElem.find('.reps input')", rowElem.find('.reps input'));
          rowElem.find('.reps input').attr('value', set.reps)
          rowElem.find('.time input').attr('value', set.time)
          rowElem.find('.lift input').attr('value', set.lift)
          rowElem.find('.rpe input').attr('value', set.rpe)
          rowElem.find('.rir input').attr('value', set.rir)
        }
      })
      this.exercisesWithActivityLoaded.push(this.exerciseId);
    }
  }

  openExercise(event){
    if(event){
      event.preventDefault();
      const dataset = event.currentTarget.dataset;
      this.exerciseId = dataset.exerciseId;
    }
    startSpinner();
    const divs = document.getElementsByClassName('exercise');
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add('hidden')
    }
    // console.log('event.target.dataset', event.currentTarget.dataset);
    const exercises = document.getElementById(`workout-exercise-${this.workoutId}`).getElementsByClassName('exercise');
    for(let j=0; j<exercises.length; j++){
      const exercise = exercises[j];
      if(exercise.dataset.id == this.exerciseId){
        this.currentExerciseIndex = j;
        break;
      }
    }
    const currentExercise = exercises[this.currentExerciseIndex];
    currentExercise.classList.remove('hidden');
    document.getElementById(`exercise-${this.exerciseId}`).classList.remove('hidden');
    if(document.querySelector(`#exercise-${this.exerciseId} .exercise-video`)){
      initVideoPlayer(`#exercise-${this.exerciseId} .exercise-video`);
    }
    this.setExerciseAndTitle();
    this.loadExistingActivities();
    // console.log('this.currentExerciseIndex', this.currentExerciseIndex);
    // this.setProgress();
    this.programOverviewTarget.classList.add('hidden');
    this.workoutViewTarget.classList.add('hidden');
    this.exerciseViewTarget.classList.remove('hidden');
    stopSpinner();
  }

  openNextExercise(){
    startSpinner();
    const divs = document.getElementsByClassName('exercise');
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add('hidden')
    }
    const exercises = document.getElementById(`workout-exercise-${this.workoutId}`).getElementsByClassName('exercise');
    // console.log('this.current exercise index', this.currentExerciseIndex);
    // console.log('exercise total', exercises.length);
    if(this.currentExerciseIndex == exercises.length-1) {
      console.log('last exercise');
      window.location = '/activity-history';
      return false;
    }
    this.currentExerciseIndex += 1;
    const currentExercise = exercises[this.currentExerciseIndex];
    // console.log('current exercise', currentExercise);
    this.exerciseId = currentExercise.dataset.id;
    currentExercise.classList.remove('hidden');
    document.getElementById(`exercise-${this.exerciseId}`).classList.remove('hidden');
    if(document.querySelector(`#exercise-${this.exerciseId} .exercise-video`)){
      initVideoPlayer(`#exercise-${this.exerciseId} .exercise-video`);
    }
    this.setExerciseAndTitle();
    this.loadExistingActivities();
    this.programOverviewTarget.classList.add('hidden');
    this.workoutViewTarget.classList.add('hidden');
    this.exerciseViewTarget.classList.remove('hidden');
    stopSpinner();
  }

  openPrevExercise(){
    if(this.currentExerciseIndex == 0){
      return false;
    }
    startSpinner();
    const divs = document.getElementsByClassName('exercise');
    for (var i = 0; i < divs.length; i++) {
      divs[i].classList.add('hidden')
    }
    this.currentExerciseIndex -= 1;
    const exercises = document.getElementById(`workout-exercise-${this.workoutId}`).getElementsByClassName('exercise');
    const currentExercise = exercises[this.currentExerciseIndex];
    this.exerciseId = currentExercise.id;
    currentExercise.classList.remove('hidden');
    // this.setProgress();
    this.setExerciseAndTitle();
    this.loadExistingActivities();
    document.getElementById(`exercise-${this.exerciseId}`).classList.remove('hidden');
    if(document.querySelector(`#exercise-${this.exerciseId} .exercise-video`)){
      initVideoPlayer(`#exercise-${this.exerciseId} .exercise-video`);
    }
    stopSpinner();
  }

  // setProgress(){
  //   const progress = ((this.currentExerciseIndex)/this.exerciseDetailsTargets.length)*100;
  //   console.log('set progress', this.exercises.length, progress);
  //   this.progressTargets[this.currentExerciseIndex].style.width = `${progress}%`;
  // }

  save(event){
    event.preventDefault();
    startSpinner();
    const exerciseId = this.exerciseId;
    // console.log('form-'+exerciseId); 
    const form = document.getElementById(`form-${exerciseId}`);
    const data = serializeForm(form);
    // console.log('data', data, exerciseId, this.exercises, this.userWeek, this.currentExerciseIndex);
    // console.log('weekdata', this.weekData);
    const formattedData = this.formatActivitiesData(data);
    // console.log('formattedData', formattedData);
    if(Object.keys(formattedData).length == 0){
      this.openNextExercise()
    } else {
      this.saveActivities(formattedData);
    }
  }

  saveActivities(formattedData){
    const that = this;
    fetch('/activities/log', {
      'method': 'post',
      'Content-Type': 'application/json',
      'body': JSON.stringify(formattedData)
    }).then(function(res){
      that.openNextExercise();
      stopSpinner();
    })
  }

  formatActivitiesData(data){
    console.log('datasets', data.sets);
    const filteredSets = this.filterDataSets(data.sets);
    const activitiesData = {};
    if(filteredSets.length == 0){
      return activitiesData;
    }
    // console.log('week data', this.weekData);
    activitiesData.week = this.userWeek;
    activitiesData.workoutTitle = this.workoutTitle;
    activitiesData.workoutId = this.workoutId;
    activitiesData.exerciseLog = {};
    const exerciseLogData = {};
    exerciseLogData.id = this.exerciseId;
    exerciseLogData.title = this.exerciseTitle;
    exerciseLogData.sets = filteredSets;
    activitiesData.exerciseLog = exerciseLogData;
    return activitiesData;
  }

  filterDataSets(sets){
    const filteredSets = [];
    for(let i=0; i<sets.length; i++) {
      const set = sets[i];
      console.log('set', set);
      if((set['time'] && set['time'].length > 0) || (set['lift'] && set['lift'].length > 0) || (set['reps'] && set['reps'].length > 0) || (set['rpe'] && set['rpe'].length > 0) || (set['rir'] && set['lift'].length > 0)){
        filteredSets.push(set);
      }
    }console.log('filteredSets', filteredSets);
    return filteredSets;
  }

  addInputRow(event=null){
    if(event){
      event.preventDefault();
    }
    const inputRowsContainer = document.getElementById(`input-rows-${this.exerciseId}`);
    const inputRows = inputRowsContainer.getElementsByClassName('input-row');
    // console.log('inpoutRows length', inputRows.length);
    const inputRowTemplate = $($(`#exercise-input-row-template-${this.exerciseId}`).html());
    // console.log('inputRowTemplate', inputRowTemplate);
    const cloneTemplate = inputRowTemplate.clone();
    const inputRowCount = inputRows.length;
    cloneTemplate.find('.input-number').text(inputRowCount + 1);
    cloneTemplate.find('.reps input').attr('name', `sets[${inputRowCount}][reps]`)
    cloneTemplate.find('.time input').attr('name', `sets[${inputRowCount}][time]`)
    cloneTemplate.find('.lift input').attr('name', `sets[${inputRowCount}][lift]`)
    cloneTemplate.find('.rpe input').attr('name', `sets[${inputRowCount}][rpe]`)
    cloneTemplate.find('.rir input').attr('name', `sets[${inputRowCount}][rir]`)
    
    $(`#input-rows-${this.exerciseId}`).append(cloneTemplate)
  }

  close(event){
    event.preventDefault();
    window.location.reload();
  }
}