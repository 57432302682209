import { auth } from "../auth";
import { Controller } from "@hotwired/stimulus"
import '../vendor/jquery';
import { Timestamp } from "firebase/firestore";
import _ from "lodash";
import Sortable from 'sortablejs';
import { startSpinner, stopSpinner } from "../ui/spinner";

export default class extends Controller {
  static targets = ["weeksContainer", 'tabs', 'weekTab', "tabContainer"];

  connect() {
    console.log('training tracker controller connected');
  }

  initialize(){
    const that = this;
    // this.weeksAmount = 4;
	  this.currentWeek = 0;
    this.programData = {};
    this.disabledMode = false;
    this.nextMondayDate = this.getNextMonday();
    auth.authStateReady().then(function(){
      const user = auth.currentUser;
      if(!user){
        window.location = '/sign-in'
      }
      // console.log('user', user);
      that.getProgramData();
    })
  }

  getCurrentTabWeek(data) {
		const enrollment_timestamp = data.subscribed_at;
		let enrollment_date = new Timestamp(enrollment_timestamp._seconds, enrollment_timestamp._nanoseconds).toDate();
    // console.log('enrollment date 0', enrollment_date);
    let monday = this.getMonday(enrollment_date);
    // const dayOfEnrollment =  enrollment_date.getDay();
    // console.log('monday', monday);
		let weekIndex = 0;
		monday.setDate(monday.getDate() + 7)
		// console.log("new Date() < enrollment_date", new Date() <= enrollment_date, new Date());
    weekIndex =  this.getWeekIndex(monday, weekIndex);
		// console.log('week index',weekIndex);
		return weekIndex;
	}

  getWeekIndex(date, weekIndex){
		// console.log('new Date', new Date());
		// console.log('enrollment_date Date', date);
		// console.log("date compare", new Date() < date);
    if(new Date() < date) {
			// console.log('if monday date', date, weekIndex);
			// console.log('returning weekIndex', weekIndex);
			return weekIndex;
    } else {
			weekIndex += 1;
			date.setDate(date.getDate() + 7)
			// console.log('else monday date', date, weekIndex);
			return this.getWeekIndex(date, weekIndex);
		}
  }

  async getProgramData(){
    const res = await fetch('/fitness/program', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    })
    if(res.status == 401){
			console.info('401 unauthorized get fitness data from overview page', auth.currentUser);
			App.logout();
			return;
		}
    const data = await res.json();
    // console.log('data', data);
    this.programData = data;
    this.hideSpinner();
    this.showBlankSlateIfNeeded();
    if (!_.isEmpty(this.programData)) {
      const endDate = this.getProgramEndDate(data);
      if(endDate <= new Date()){
        window.location = '/the-fitness-programme/';
        return false;
      }
      this.currentWeek = this.getCurrentTabWeek(this.programData);
      this.renderWeeksAndDays(data);
      this.setupTabs();
    }
  }
  
  getEnrollmentDate(data) {
		const enrollmentTimestamp = data.subscribed_at;
		const enrollmentDate = new Timestamp(enrollmentTimestamp._seconds, enrollmentTimestamp._nanoseconds).toDate();
    // console.log('enrollment date', enrollmentDate);
		return enrollmentDate;
	}

	getProgramEndDate(data){
		const enrollmentDate = this.getEnrollmentDate(data);
		// console.log('enrollmentDate', enrollmentDate);
    const firstWorkoutDay = this.getFirstWorkoutDay(data);
		let weekRepetition = parseInt(data.week_repetition);
    // console.log('enrollment day', firstWorkoutDay,  enrollmentDate.getDay());
    if(firstWorkoutDay == 0 || firstWorkoutDay < enrollmentDate.getDay()){
      weekRepetition += 1;
    }
		// console.log('weekRepetition', weekRepetition);
    const startWeekMonday = this.getMonday(enrollmentDate);
		let endDate = new Date(startWeekMonday);
		endDate.setDate(startWeekMonday.getDate() + (weekRepetition * 7));
		// console.log('endDate', endDate);
		// console.log('endDate monday', endDate);
		return endDate;
	}

  getFirstWorkoutDay(data){
    const workoutDays = Object.keys(data.weeks[0]);
    // console.log('workoutDays', workoutDays);
    return parseInt(workoutDays[0]);
  }

	hideSpinner() {
		$("#training-loading").remove();
	}
	
	showBlankSlateIfNeeded() {
		if (_.isEmpty(this.programData)) {
			$("#training-blank-slate").removeClass("hidden");
		}
	}

  getEnrollmentDay(){
    const enrollment_timestamp = this.programData.subscribed_at;
    // console.log('day of enrollment', enrollment_timestamp);
    // console.log('get enrollment day',  new Date(enrollment_date._seconds*1000));
    // console.log('Object.keys(this.programData.weeks[0])', Object.keys(this.programData.weeks[0]));
    const enrollment_date = new Timestamp(enrollment_timestamp._seconds, enrollment_timestamp._nanoseconds).toDate();
    // console.log('enrollment date', enrollment_date);
    const dayOfEnrollment =  enrollment_date.getDay()
    return dayOfEnrollment;
  }

  setWeekCount(){
    const dayOfEnrollment = this.getEnrollmentDay();
    const assignedDays = Object.keys(this.programData.weeks[0]);
    let firstAssignedDay;
    if(assignedDays[0] == 0 && assignedDays.length > 1){
      firstAssignedDay = assignedDays[1]
    } else {
      firstAssignedDay = assignedDays[0]
    }
    if(firstAssignedDay < dayOfEnrollment || dayOfEnrollment == 0) {
      this.weeksAmount += 1;
      this.disabledMode = true;
    }
  }

  renderTabs(weekCountMax){
    let tabsTemplate = `<div class="tfm-tabs full scrollable">`;
    for(let i=0; i<weekCountMax; i++){
      tabsTemplate += this.renderTab(i);
    }
    tabsTemplate += `</div>`;
    this.tabsTarget.innerHTML = tabsTemplate;
    // console.log('weekCountMax', weekCountMax);
  }

  renderTab(weekCount) {
    const tabTemplate = `<button data-target="weekTab" data-action="click->overview#showTab" data-tab="tab-week-${weekCount}" class="${weekCount == this.currentWeek ? 'active' : ''}">
                        <span class="checkmark text-red -ml-2 mr-2 text-subtext hidden">
                          <i class="far fa-circle-check"></i>
                        </span>
                        Week ${weekCount+1}
                        </button>
                        <span></span>`
    return tabTemplate;
    // $(".tfm-tabs").append(tabTemplate);
  }

  showTab(event){
    const target = event.currentTarget;
    const tab = target.dataset.tab;
    this.weekTabTargets.forEach(element => {
      element.classList.remove('active');
    });
    target.classList.add('active');
    const tabContainer = document.getElementById(tab);
    this.tabContainerTargets.forEach(element => {
      element.classList.add('hidden');
    });
    // console.log('show tab', target, tab, this.weekTabTargets, tabContainer, this.tabContainerTargets);
    tabContainer.classList.remove('hidden');
  }

	setupTabs() {
		$(".tfm-tabs button").on('click', function(e) {
			e.preventDefault;
			const button = $(this);
			
			// Tab switching
			button.parent().find('button.active').removeClass('active');
			button.addClass('active');
			
			// Content switching
			button.parent().find('button').each( function() {
				const selector = $(this).data('tab');
				const tab = $('.' + selector);
				
				if ( $(this).hasClass('active') ) {
    			tab.removeClass('hidden');
				} else {
    			tab.addClass('hidden');
				}
				
				// scrollToActiveTab()
			});
		});
		
		this.scrollToActiveTab()
	}
	
	scrollToActiveTab() {
		var container = $('.tfm-tabs');
		var activeTab = container.find('button.active');
		if (activeTab.length) {
      var containerWidth = container.outerWidth();
      var activeTabWidth = activeTab.outerWidth();
      var tabPosition = activeTab.position().left;
      var currentScroll = container.scrollLeft();
      var elementStart = tabPosition + currentScroll;
      var elementEnd = elementStart + activeTabWidth;
      if (elementStart < currentScroll || elementEnd > currentScroll + containerWidth) {
          var newScrollPosition = elementStart - (containerWidth / 2) + (activeTabWidth / 2);
          container.scrollLeft(newScrollPosition);
      }
		}
	}
	
	// Create weeks with tab IDs
  renderWeeksAndDays(data){
    const that = this;
    const enrollmentDay = that.getEnrollmentDay();
    that.weeksAmount = data.weeks.length*parseInt(data.week_repetition);
    // console.log('that.weeks count', that.weeksAmount, data.weeks.length, data.week_repetition);
    that.setWeekCount();
    const enrollment_timestamp = this.programData.subscribed_at;
    // console.log('day of enrollment', enrollment_timestamp);
    // console.log('get enrollment day',  new Date(enrollment_date._seconds*1000));
    // console.log('Object.keys(this.programData.weeks[0])', Object.keys(this.programData.weeks[0]));
    const enrollment_date = new Timestamp(enrollment_timestamp._seconds, enrollment_timestamp._nanoseconds).toDate();
    let dayDate = that.getMonday(enrollment_date);
    let programUrl = data.url;
    let weekCount = 0
    // console.log('that.weeksAmount', that.weeksAmount);
    // console.log('data', data);
    for(let i=0; i < that.weeksAmount; i++){
      // for(let j=0; j<data.weeks.length; j++){
      // console.log('that.weeksAmount', that.weeksAmount, data.weeks.length);
      const j = that.weeksAmount % data.weeks.length;
      // console.log('j', j);
      const weekData = data.weeks[j];
      
        // console.log('weekCount', i, j, weekCount);
      const week = $("<div/>").addClass("week").attr("data-tab","tab-week-"+weekCount).attr('id', "tab-week-"+weekCount).attr('data-overview-target', 'tabContainer');
      if (i != that.currentWeek) {
        week.addClass("hidden");
      }
      $(".weeks").append(week);
      $.each(Array(7), function(d) {
        const day = ((weekCount * 7)+d+1)%7;
        const workoutData = weekData[day];
        let dayDiv;
        let userWeek = i;
        // console.log('day', day);
        // console.log('d', d);
        // console.log('dayDate', dayDate);
        let disabled = false
        if(that.disabledMode){
          if((i == 0 && ((enrollmentDay > d+1) || enrollmentDay == 0)) || (enrollmentDay !== 0 && d+1 >= enrollmentDay && i == that.weeksAmount-1)) {
            // console.log('disabled for week', i, 'day ', d, 'enrollment day ', enrollmentDay);
            disabled = true
          }
        }
        if(workoutData == undefined){
          dayDiv = that.makePlaceholderItem(dayDate, disabled);
        } else {
          const exercises = workoutData.exercises.length;
          // let finishedDate = new Date(dayDate);
          const title = workoutData.title;
          let workoutActivity;
          let activityDate;
          let url = programUrl+"?w="+workoutData.id;
          if(that.disabledMode){
            if(i > 0 && ((d+1 < enrollmentDay) || enrollmentDay == 0)){
              userWeek = i-1;
            }
          } 
          if(!disabled) {
            url += "&week="+userWeek;
            if (data.activities){
              // console.log('userWeek', userWeek);
              // console.log('currentWeek', weekCount);
              workoutActivity = data.activities.find(activity => activity.week == userWeek && activity.workoutId == workoutData.id);
              if(workoutActivity) {
                activityDate = new Timestamp(workoutActivity.date._seconds, workoutActivity.date._nanoseconds).toDate();
              }
            }
          }
          // finishedDate.setDate(dayDate.getDate() - 5);
          // console.log('workout activities', workoutActivity, activityDate);
          dayDiv = that.makeDayItem(dayDate, title, url, exercises, activityDate, workoutData.id, disabled);  
        }
        
        // console.log('dayDiv', dayDiv);
        week.append(dayDiv);
        dayDate.setDate(dayDate.getDate()+ 1);
      });
      weekCount += 1;
    }
    that.renderTabs(weekCount);
    that.initSortable();
  }
	
	makePlaceholderItem(date, disabled=false) {
		var element, title;
    let placeholder = $('template#day-placeholder');
	  let placeholder_today = $('template#day-placeholder-today');
	
		if ( this.isToday(date) ) {
    	element = $( placeholder_today.html() );
		} else {
    	element = $( placeholder.html() );
		}
		element[0].dataset.id = 0;
		const weekday = new Date(date).getDay();

    if (weekday == 1) { // Monday
			title = "Today's a rest day—take a breather!";
		} else if (weekday == 2) { // Tuesday
			title = "No workout today. Relax and enjoy!";
    } else if (weekday == 3) { // Wednesday
			title = "Rest up! Your body deserves a break.";
    } else if (weekday == 4) { // Thursday
			title = "Recharge time! No workouts today.";
    } else if (weekday == 5) { // Friday
			title = "Off day: Perfect time to relax and unwind.";
    } else if (weekday == 6) { // Saturday
			title = "Rest today, conquer tomorrow!";
		} else if (weekday == 0) { // Sunday
			title = "Chill out today, no workout needed.";
		}
		
		element.find(".title").find("span").text(title);
		
		this.setDates(element, date);
    if(disabled){
      element.addClass('disabled');
    }
		
		return element;
	}
	
	makeDayItem(date, title, url, exercises, finishedDate, workoutId, disabled=false) {
		var element, title;
		let item = $('template#day-item');
	  let item_today = $('template#day-item-today');
    // console.log('makeDayItem', date);
		if ( this.isToday(date) ) {
    	element = $( item_today.html() );
		} else {
    	element = $( item.html() );
		}
		
		element.find(".title").find("a").text(title);
		element.find(".title").find("a").attr("href", url);
		element.find(".subtitle").text(exercises + " exercises");
    // console.log('day element', element[0]);
    element[0].dataset.id = workoutId;
		
		this.setDates(element, date, finishedDate);
		this.setCompletionDate(element, date, finishedDate);
    if(disabled){
      element.addClass('disabled');
    }
    element.addClass('linkedDayItem');
		
		return element;
	}
	
	setDates(el, date) {
    let parsedDate = new Date( Date.parse(date) );
    let day = parsedDate.toLocaleDateString("en-US", { day: "numeric" });
    let month = parsedDate.toLocaleDateString("en-US", { month: "short" });
		el.find(".day").text(day);
		el.find(".month").text(month);
		
		const weekday = new Date(date).getDay();
		let overtitle;
		if ( this.isToday(date) ) {
			overtitle = "Today!";
		} else {
    	if (weekday == 1) {
				overtitle = "Monday";
			} else if (weekday == 2) {
				overtitle = "Tuesday";
    	} else if (weekday == 3) {
				overtitle = "Wednesday";
    	} else if (weekday == 4) {
				overtitle = "Thursday";
    	} else if (weekday == 5) {
				overtitle = "Friday";
    	} else if (weekday == 6) {
				overtitle = "Saturday";
			} else if (weekday == 0) {
				overtitle = "Sunday";
			}
		}
		el.find(".overtitle").text(overtitle);
	}
	
	setCompletionDate(element, date, finishedDate) {
		//
		//  Completion text
		//  ---------------
		//
    //  Usually assigned date and finished date are the same BUT if for
		//  example user fills out Monday workout and then moves that workout
		//  to Tuesday, Tuesday workout needs to indicate when it was filled.
		//
		//  It is also possible to pass null as finished date, which means
		//  that workout has not been finished yet.
		//
		
		// Finished on same date, show checkmark only, hide the label
		if ( this.isSameDay(date, finishedDate) ) {
    	element.find(".accessory-text").text("Finished");
			
		// Finished date missing, hide entire accessory
		} else if (finishedDate == null) {
    	element.find(".accessory").remove();
			
		// Finished date is different from day date, show the difference
		} else if ( !this.isSameDay(date, finishedDate) ) {
    	let finishedDateString = finishedDate.toLocaleDateString("en-US", { month: "short", day: "numeric" });
    	element.find(".accessory-text").text("Finished on " + finishedDateString)
		}
	}
	
	
	// Helpers
	
	isToday(date) {
  	return new Date().toDateString() === new Date(date).toDateString();
	}
	
	isSameDay(date1, date2) {
  	return new Date(date1).toDateString() === new Date(date2).toDateString();
	}

	getNextMonday(date) {
    let today;
    if(date){
      today = date;
    } else {
      today = new Date();
    }
    const dayOfWeek = today.getDay();
    let daysUntilMonday = (1 - dayOfWeek + 7) % 7;
    if (daysUntilMonday === 0) {
        return today; // Return today if it's Monday
    }
    const nextMonday = new Date(today);
    nextMonday.setDate(today.getDate() + daysUntilMonday);
    return nextMonday;
	}

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }
	
  initSortable() {
    const weekElements = document.getElementsByClassName('week');
    // console.log('weekElements', weekElements);
    for(let i =0; i < weekElements.length; i++){
      const weekElement = weekElements[i];
      Sortable.create(weekElement, {
        handle: ".handle",
        dataIdAttr: 'data-id',
        // draggable: ".draggable",
        store: {
          set: async function (sortable) {
              startSpinner();
              var order = sortable.toArray();
              console.log('order', order);
              const res = await fetch('/workout/reorder', {
                method: 'POST',
                body: JSON.stringify({workoutIds: order}),
                headers: {
                  'Content-Type': 'application/json',
                  'accept': 'application/json'
                }
              })
              // console.log(res);
              stopSpinner();
              window.location.reload();
          }
        }
      });
    }
  }
}