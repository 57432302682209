import '../vendor/jquery';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

jQuery(function() {
  CookieConsent.run({
    guiOptions: {
        consentModal: {
            layout: "box wide",
            position: "bottom left",
            equalWeightButtons: false,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        analytics: {}
    },
    language: {
        default: "en",
        translations: {
            en: {
                consentModal: {
                    title: "We value your privacy!",
                    description: "We use cookies to analyse site traffic and enhance our services. By clicking “Accept,” you consent to our use of cookies for these purposes. You can manage your preferences at any time in your browser settings.",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences"
                },
                preferencesModal: {
                    title: "Consent Preferences",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    closeIconLabel: "Close modal",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Cookie Usage",
                            description: "We respect your privacy and aim to give you control over your experience. Our website uses cookies to analyse site traffic and improve our services. You can choose which types of cookies you would like to accept or decline. Your choices will help us provide a tailored browsing experience to suit your needs."
                        },
                        {
                            title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                            description: "Strictly necessary cookies are essential for the basic functionality of our website. These cookies enable critical features such as user authentication, maintaining session information, and ensuring security during your browsing experience. Without these cookies, certain functions, like remembering your login status, would not work properly. Importantly, strictly necessary cookies do not require user consent under data protection regulations, as they are vital for delivering the services you request.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytics Cookies",
                            description: "Analytics cookies are used to collect data on how visitors interact with our website. These cookies track metrics such as the number of visitors, pages viewed, and time spent on the site. By analysing this information, we gain valuable insights into user behaviour, which helps us improve website functionality and enhance the overall user experience. Importantly, analytics cookies do not identify individual users; instead, they aggregate data to inform our decisions and optimise our services. User consent is required for the use of these cookies under privacy regulations.",
                            linkedCategory: "analytics"
                        },
                        {
                            title: "More information",
                            description: "For more detailed information about the types of cookies we use and how they affect your browsing experience, please review our <a class=\"cc__link\" target=\"_blank\" href=\"/cookie-policy/\">Cookie Policy</a>."
                        }
                    ]
                }
            }
        }
    },
    disablePageInteraction: true
  });
});