export function  exerciseDetailsTemplate(exercise, workoutId, exerciseSets, subIndex, superset, setSize, index) {
  return `<div class="exercise fixed inset-0 z-exercise bg-surface-base hidden" data-id="${exercise.id}" id="exercise-${exercise.id}">
    <div class="flex flex-col size-full overflow-auto">
      ${exerciseDetailsNav(index)}
      <div class="flex-1 container-default mobile-edge-to-edge">
        <div class="flex lg:flex-row flex-col size-full 2xl:gap-24 xl:gap-16 gap-12">

          <!-- Overview -->
          ${exerciseDetailsOverview(exercise, index, superset, setSize, subIndex)}
          <!-- User form -->
          <div
            class="records xl:flex-1 lg:flex-none flex-1 xl:w-full lg:w-3/5 w-full bg-white !pb-10 xl:p-14 xs:p-12 p-5 rounded-t-large shadow-block flex flex-col justify-between">
            <div class="sets space-y-3.5">
              <form id="form-${exercise.id}">
              <div class="space-y-1.5 pb-10 xs:pt-0 pt-5">
                <h2 class="text-primary text-h3">Track your progress</h2>
                <p class="text-tertiary text-body text-pretty">Amount of sets and placeholders are recommended goals.</p>
              </div>

              <!-- Records table -->
              <div class="flex items-center gap-2.5 text-subtext text-tertiary border-b border-surface-secondary pb-3.5 mb-2.5">
                <div class="flex-none w-8 xs:block hidden">
                  Set
                </div>
                <div class="flex-1 xs:pl-4.5 ${exercise.lift == 'true' ? '':'hidden'}">Lift</div>
                <div class="flex-1 xs:pl-4.5 ${exercise.reps == 'true' ? '':'hidden'}">Reps</div>
                <div class="flex-1 xs:pl-4.5 ${exercise.time == 'true' ? '':'hidden'}">Time</div>
                <div class="flex-1 xs:pl-4.5 ${exercise.rpe == 'true' ? '':'hidden'}">RPE</div>
                <div class="flex-1 xs:pl-4.5 ${exercise.rir == 'true' ? '':'hidden'}">RIR</div>
              </div>
              <div class="input-rows space-y-2.5" id="input-rows-${exercise.id}">
                ${exerciseInputRows(exercise, workoutId, exerciseSets)}
              </div>
              <!-- New record line button -->
              <div>
                <button class="flex items-center gap-2.5 py-2 text-link group active:opacity-50"
                  data-exercise-id="${exercise.id}" data-action="click->fitness#addInputRow">
                  <div class="w-8 text-left text-caption">
                    <i class="fal fa-plus"></i>
                  </div>
                  <div class="text-body group-hover:underline">
                    Add set
                  </div>
                </button>
              </div>
              </form>
            </div>
            ${timerTemplate(exercise, workoutId, index)}
          </div>
        </div>
      </div>
    </div>
  </div>`
}

function exerciseInputRows(exercise, workoutId, exerciseSets){
  let existingExerciseInputRows = '';
  for(let i =0; i < exerciseSets.length; i++){
    // console.log('input row index', i);
    existingExerciseInputRows += inputRow(exercise, workoutId, i, exerciseSets[i]);
  }
  const extraInputRowCount = parseInt(exercise.sets) - exerciseSets.length;
  // console.log('exercise.sets count', exercise.sets);
  // console.log('existing exerciseSets', exerciseSets);
  // console.log('extraInputRowCount', extraInputRowCount);
  if(extraInputRowCount > 0){
    for(let j = 0; j < extraInputRowCount; j++){
      existingExerciseInputRows += inputRow(exercise, workoutId, j, exerciseSets.length);
    }
  }
  return existingExerciseInputRows;
}

function exerciseDetailsNav(index){
  return `<div class="flex justify-between items-center md:p-8 xs:p-6 p-5 xs:gap-8 gap-5">
      <div class="size-12">
        ${index > 0 ? '<button class="flex items-center justify-center size-12 bg-surface-secondary hover:bg-white rounded-full text-xl text-tertiary hover:text-link active:translate-y-px hover:shadow-xl active:shadow transition" data-action="click->fitness#openPrevExercise"><i class="fal fa-chevron-left"></i></button>' : ''}
      </div>
      <div class="sm:w-96 xs:w-64 w-48 p-0.5 bg-white rounded-full">
        <div class="bg-link h-1 rounded-full" style="" data-fitness-target="progress"></div>
      </div>
      <div class="size-12">
        <button class="flex items-center justify-center size-12 bg-surface-secondary hover:bg-white rounded-full text-xl text-tertiary hover:text-link active:translate-y-px hover:shadow-xl active:shadow transition" data-action="click->fitness#close">
          <i class="fal fa-xmark"></i>
        </button>
      </div>
    </div>`
}

function timerTemplate(exercise, workoutId, index) {
  return `<div>
            <!-- Timer records -->
            <template class="time-record hidden">
              <li class="bg-gray-100 px-3.5 py-2 rounded-full font-mono text-caption">
                <span class="order text-tertiary">1</span>
                <span class="time">00:00</span>
              </li>
            </template>
            <ol class="timer-records flex flex-wrap gap-2.5 py-2.5 hidden"></ol>

            <!-- Exercise buttons -->
            <div class="flex sm:flex-row flex-col justify-stretch gap-2.5">
              <div class="timer-controls flex-1 *:flex *:gap-2.5">
                <div class="timer-init">
                  <button type="button" class="tfm-button wide secondary" onclick="App.startTimer(this)">
                    <i class="fal fa-stopwatch -mt-0.5"></i>
                    <span>
                      Start Timer
                    </span>
                  </button>
                </div>
                <div class="timer-active !hidden">
                  <button type="button" id="play-${exercise.id}" class="tfm-button wide secondary"
                    onclick="App.pauseTimer(this)">
                    <i class="fal fa-pause -mt-0.5"></i>
                    <span>
                      00:00
                    </span>
                  </button>
                  <button type="button" id="" class="tfm-button secondary" onclick="App.setLap(this)">
                    <span class="px-2">
                      Lap
                    </span>
                  </button>
                </div>
                <div class="timer-paused !hidden">
                  <button type="button" id="pause-${exercise.id}" class="tfm-button wide secondary"
                    onclick="App.resumeTimer(this)">
                    <i class="fal fa-play -mt-0.5"></i>
                    <span>
                      00:00
                    </span>
                  </button>

                  <button type="button" id="" class="tfm-button secondary" onclick="App.resetTimer(this)">
                    <span class="px-2">
                      Reset
                    </span>
                  </button>
                </div>
              </div>
              <a href="" class="tfm-button wide primary" data-action="click->fitness#save" data-exercise-id="${exercise.id}" data-workout-id="${workoutId}" data-exercise-index="${index}">
                  <i class="fal fa-check -mt-0.5"></i>
                  <span>
                    Save &amp; Continue
                  </span>
                </a>
            </div>
          </div>`
}

function exerciseDetailsOverview(exercise, index, superset, setSize, subIndex){
  return `<div class="description lg:flex-1 space-y-5 pt-2.5 xs:pb-12 pb-0 xs:px-0 px-5">
          <div
            class="text-2xl size-9 rounded-full border-[1.5px] border-link text-link flex items-center justify-center overflow-hidden">
            <span>${index+1}</span>
          </div>

          <h3 class="text-h2">
            ${exercise.title}
            ${superset ? '<div class="text-caption text-link mt-1.5">Superset:'+ subIndex+'/'+ setSize +'</div>' : '' }
          </h3>
          
          ${exerciseBadges(exercise)}

          <div class="prose">
            <p>${exercise.description}</p>
          </div>
          <p class="block text-tertiary text-caption mt-1">
            ${exercise.note}
          </p>
          ${exercise.apivideo_id ? '<div class="pt-8"><div class="exercise-video tfm-player aspect-video bg-black rounded-normal overflow-hidden" data-video-id="'+exercise.apivideo_id+'" data-video-title="'+exercise.title+'"></div></div>' : '' }
        </div>`
}

function input(icon, input_id, input_name, placeholder, data_exercise_id, data_workout_id, hide_icon_on_mobile, index, value) {
  return `<div class="flex gap-2 ${icon ? 'has-icon' : ''} ${hide_icon_on_mobile ? 'hide-icon-on-mobile': ''}">
    ${icon ? '<div class="icon"><i class="fal fa-'+icon+'"></i></div>' : ''}
    <input type="text" name="sets[${index}][${input_name}]" type="text" ${ input_id ? 'id="'+input_id +'"' : ''} ${ input_name ? 'name="'+input_name +'"' : ''} ${ placeholder ? 'placeholder="'+placeholder +'"' : ''}
    ${ data_exercise_id ? 'data-exercise-id="'+data_exercise_id +'"' : ''} ${ data_workout_id ? 'data-workout-id="'+data_workout_id +'"' : ''}
    ${ value ? 'value='+value : ''}
    />
  </div>`
}

function exerciseBadges(exercise){
  return `<div class="badges flex flex-wrap gap-1.5">
            ${exerciseBadge('', exercise, 'sets', 'sets', false)}
            ${exerciseBadge('repeat', exercise, 'reps', 'reps', false)}
            ${exerciseBadge('stopwatch', exercise, 'time_min', 'seconds', false)}
            ${exerciseBadge('wave-pulse', exercise, 'tempo', '', false)}
            ${exerciseBadge('timer', exercise, 'rest', 'seconds rest', false)}
          </div>`
}

export function inputRow(exercise, workoutId, index, value={}) {
  // console.log('row index', index);
  return `<div class="input-row flex items-center gap-2.5">
            <div class="input-number xs:block hidden flex-none w-8 text-body text-primary after:content-['.']">${index+1}
            </div>
            ${exercise['lift'] == 'true' ? input('dumbbell', '', 'lift', exercise.lift_goal, exercise.id, workoutId, true, index, value['lift']) : ''}
            ${exercise['reps'] == 'true' ? input('repeat', '', 'reps', exercise.reps_goal, exercise.id, workoutId, true, index, value['reps']) : ''}
            ${exercise['time'] == 'true' ? input('stopwatch', '', 'time', exercise.time_goal, exercise.id, workoutId, true, index, value['time']) : ''}
            ${exercise['rpe'] == 'true' ? input('person-running', '', 'rpe', exercise.rpe_goal, exercise.id, workoutId, true, index, value['rpe']) : ''}
            ${exercise['rir'] == 'true' ? input('heart-circle-plus', '', 'rir', exercise.rir_goal, exercise.id, workoutId, true, index, value['rir']) : ''}
          </div>
    `
}

export function renderExerciseList(exercise, userWeek, index, showIndex=true){
  console.log|('render exercise list called');
  let indexText = '';
  if(showIndex || index == 0) {
    indexText = `<div class="flex-none flex items-center justify-center text-body text-link size-7 border border-link rounded-full"><span>${index+1}</span></div>`;
  } else {
    indexText = ''
  }
    
  const exerciseListTemplate = `<li class="flex gap-5" data-exercise-id="${exercise.id}" data-user-week="${userWeek}">
    ${ indexText}
    <div class="pt-0.5 pb-5 border-b border-gray-200">
      <div class="text-body font-bold text-primary mb-0.5">
        <a href="" class="hover:text-link" data-exercise-id="${exercise.id}" data-user-week="${userWeek}" data-action="click->fitness#openExercise">${exercise.title}</a>
        <a href="" class="text-xs text-link hover:bg-link/5 py-1 px-1.5 rounded-full relative -top-0.5" data-exercise-id="${exercise.id}" data-user-week="${userWeek}" data-action="click->fitness#openExercise">
          <i class="fad fa-eye"></i>
        </a>
      </div>
      <div class="prose prose-small">
        <p>${exercise.description}</p>
      </div>
      <small class="block text-tertiary text-caption mt-1">
        ${exercise.note}
      </small>
      <div class="pt-2.5">
        <div class="badges flex flex-wrap gap-1.5">
          ${exerciseBadge('', exercise, 'sets', 'sets')}
          ${exerciseBadge('repeat', exercise, 'reps', 'reps')}
          ${exerciseBadge('stopwatch', exercise, 'time_min', 'seconds')}
          ${exerciseBadge('wave-pulse', exercise, 'tempo', '')}
          ${exerciseBadge('timer', exercise, 'rest', 'seconds rest')}
        </div>
      </div>
    </div>
  </li>`
  return exerciseListTemplate;
}

export function renderSubExerciseLists(exercise, userWeek, index){
  let subExerciseLists = '';
  exercise.exercises.forEach((subExercise) => {
    subExerciseLists += subExerciseListTemplate(subExercise, userWeek);
  })
  const exerciseList = `<div class="pb-5">
      <div class="pl-5 ml-7 mb-0.5 text-subtext text-link">Superset</div>
      <div class="flex gap-5">
        <div class="flex-none flex items-center justify-center text-body text-link size-7 border border-link rounded-full">
          <span>${index+1}</span>
        </div>
        <div class="pb-5 border-b border-gray-200">
          <ol>
            ${subExerciseLists}    
          </ol>
        </div>
      </div>
    </div>
`;
  return exerciseList;
}

function subExerciseListTemplate(exercise, userWeek){
  let subExerciseList = `<li class="flex gap-5 pb-5" data-exercise-id="${exercise.id}" data-user-week="${userWeek}">
      <div class="pt-0.5">
        <div class="text-body font-bold text-primary mb-0.5">
          <a href="" class="hover:text-link" data-exercise-id="${exercise.id}" data-user-week="${userWeek}" data-action="click->fitness#openExercise">${exercise.title}</a>
          <a href="" class="text-xs text-link hover:bg-link/5 py-1 px-1.5 rounded-full relative -top-0.5" data-exercise-id="${exercise.id}" data-user-week="${userWeek}" data-action="click->fitness#openExercise">
            <svg class="svg-inline--fa fa-eye" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="eye" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><g class="fa-duotone-group"><path class="fa-secondary" fill="currentColor" d="M0 256c0 4.2 .8 8.4 2.5 12.3C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c1.6-3.9 2.5-8.1 2.5-12.3s-.8-8.4-2.5-12.3c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32s-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7C.8 247.6 0 251.8 0 256zm432 0a144 144 0 1 1 -288 0 144 144 0 1 1 288 0z"></path><path class="fa-primary" fill="currentColor" d="M224 256c35.3 0 64-28.7 64-64c0-7.1-1.2-13.9-3.3-20.3c-1.8-5.5 1.6-11.9 7.4-11.7c40.8 1.7 77.5 29.6 88.6 71.1c13.7 51.2-16.7 103.9-67.9 117.6s-103.9-16.7-117.6-67.9c-1.9-6.9-2.9-13.9-3.2-20.7c-.3-5.8 6.1-9.2 11.7-7.4c6.4 2.1 13.2 3.3 20.3 3.3z"></path></g></svg>
          </a>
        </div>
        <div class="prose prose-small">
          <p>${exercise.description}</p>
        </div>
          <small class="block text-tertiary text-caption mt-1">
            ${exercise.note}
          </small>
        <div class="pt-2.5">
          <div class="badges flex flex-wrap gap-1.5">
            ${exerciseBadge('', exercise, 'sets', 'sets')}
            ${exerciseBadge('repeat', exercise, 'reps', 'reps')}
            ${exerciseBadge('stopwatch', exercise, 'time_min', 'seconds')}
            ${exerciseBadge('wave-pulse', exercise, 'tempo', '')}
            ${exerciseBadge('timer', exercise, 'rest', 'seconds rest')}
            
          </div>
        </div>
      </div>
    </li>`
    return subExerciseList;
}

function exerciseBadge(icon, exercise, attr, suffix='', list=true){
  const exerciseListBadgeClass=`flex-none flex items-center px-3 py-1 gap-1.5 bg-surface-secondary rounded-full cursor-default ${exercise[attr] ? '' : 'hidden'}`;
  const exreciseDetailBadgeClass=`flex-none flex items-center px-3.5 py-1 gap-2 bg-link rounded-full ${exercise[attr] ? '' : 'hidden'}`;
  const exerciseListSymbolClass = `fal fa-${icon} text-caption text-secondary`;
  const exerciseDetailSymbolClass = `fal fa-${icon} text-white -ml-0.5`;
  const listSpanClass = "text-caption text-secondary";
  const detailsSpanClass = "text-caption text-white";

  let iconElem='';
  let badgeSpan;
  let badgeClass;
  if(list){
    badgeClass = exerciseListBadgeClass;
    spanClass=listSpanClass;
    if(icon){
      iconElem = `<i class="${exerciseListSymbolClass}"></i>`;
    }
  } else {
    badgeClass = exreciseDetailBadgeClass;
    spanClass = detailsSpanClass;
    if(icon){
      iconElem = `<i class="${exerciseDetailSymbolClass}"></i>`;
    }
  }
  if(attr == 'reps'){
    badgeSpan = `<span class="${spanClass}">${exercise.reps_min}${exercise.reps_max && exercise.reps_max > exercise.reps_min ? '-'+exercise.reps_max : ''} reps ${exercise.reps_each_side ? ' each side' : ''}</span>`
  } else {
    badgeSpan = `<span class="${spanClass}">${exercise[attr]} ${suffix}</span>`
  }
  return `<div class="${badgeClass}">${iconElem}${badgeSpan}</div>`;
}

export function beginWorkoutBtnTemplate(){
 return `<div class="text-center xs:mb-18 mb-6 xs:-mt-10 sm:-mt-8 -mt-2">
          <button type="button" id="" class="tfm-button primary" data-action="click->fitness#beginWorkout">
            <i class="fal fa-play -mt-0.5" ></i>
            <span class="px-2">
              Begin Workout
            </span>
          </button>
        </div>`;
}

export function serializeForm(form) {
  return Array.from((new FormData(form)).entries())
    .reduce((data, [field, value]) => {
      let [_, prefix, keys] = field.match(/^([^\[]+)((?:\[[^\]]*\])*)/);

      if (keys) {
        keys = Array.from(keys.matchAll(/\[([^\]]*)\]/g), m => m[1]);
        value = update(data[prefix], keys, value);
      }
      data[prefix] = value;
      return data;
    }, {});
}

function update(data, keys, value) {
  if (keys.length === 0) {
    // Leaf node
    return value;
  }

  let key = keys.shift();
  if (!key) {
    data = data || [];
    if (Array.isArray(data)) {
      key = data.length;
    }
  }

  // Try converting key to a numeric value
  let index = +key;
  if (!isNaN(index)) {
    // We have a numeric index, make data a numeric array
    // This will not work if this is a associative array 
    // with numeric keys
    data = data || [];
    key = index;
  }
  
  // If none of the above matched, we have an associative array
  data = data || {};

  let val = update(data[key], keys, value);
  data[key] = val;

  return data;
}