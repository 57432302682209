import '../vendor/jquery';

$(document).ready(function() {
	
	// Input interaction
	$('nav#top .search input').on('keydown', function(event) {
		const inputValue = $(this).val().trim();
		if (event.key === "Enter" && inputValue) {
			window.location.href = `/search/?q=${encodeURIComponent(inputValue)}`;
		}
	});
	
	// Init Pagefind if possible
	if ($("#pagefind").length) {
		let pagefind = new PagefindUI({
			element: "#pagefind",
			showSubResults: true,
			resetStyles: true,
			autofocus: true,
			translations: {
				placeholder: "Search..."
			}
		});
	
		// Grab search query from url if present and pass to page find
		const urlObj = new URL(window.location.href);
		if (urlObj.pathname.includes('/search/') && urlObj.searchParams.has('q')) {
			const q = urlObj.searchParams.get('q');
			pagefind.triggerSearch(q);
		}
	};
	
});
