// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { app } from './utils/firebase';
import { Controller } from "@hotwired/stimulus"
import { startSpinner, stopSpinner } from "../ui/spinner";
import { auth } from "../auth";

export default class extends Controller {
  static targets = ['purchaseBtnContainer'];

  async purchaseBtnContainerTargetConnected(){
    console.log('purchae btn connected');
    startSpinner();
    const dataset = this.purchaseBtnContainerTarget.dataset;
    const price_id = dataset.stripePrice;
		const price = await this.getPrice(price_id);
    const btnText = `Purchase webinar &sdot; &pound;${price}`;
    const btnElem = document.getElementById(dataset.webinarId);
    const spanElem = btnElem.querySelector("span");
    // this.purchaseBtnContainerTarget.
    spanElem.innerHTML = btnText;
    stopSpinner();
    // console.log('price', price);
  }

  async getPrice(price_id) {
    return await fetch(`/price/${price_id}`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      }
    }).then((res) => res.json())
    .then((json) => {
      console.log('json', json.unit_amount/100);
      const price = json.unit_amount/100;
      return price;
    })
  }

  async checkout(event) {
    startSpinner();
    auth.authStateReady().then(async function(){
			const user = auth.currentUser;
      if(!user){
        window.location = '/sign-in'
      }
      event.preventDefault();
      const parentNode = event.currentTarget.parentNode;
      console.log('event', parentNode.dataset);
      const price_id = parentNode.dataset.stripePrice;
      console.log('price_id', price_id);
      fetch(`/price/${price_id}/purchase`, {
        method: 'post',
        body: JSON.stringify({ webinar_id: parentNode.dataset.webinarId, webinar_url: parentNode.dataset.webinarUrl }),
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      }).then((res) => res.json())
      .then((json) => {
        window.location = json.url;
      }).catch((error) => {
        console.log('error', error);
        stopSpinner();
      })
    })
  }
}