import { Controller } from "@hotwired/stimulus";
import _ from "lodash";
import { startSpinner, stopSpinner } from "../ui/spinner";
import {  fetchSignInMethodsForEmail, signInWithEmailAndPassword, sendEmailVerification, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from "../auth";

export default class extends Controller {
  static targets = ['emailContainer', 'nameContainer', 'passwordContainer', 'newPasswordContainer', 'errorContainer', 'title', 'forgotPasswordLink', 'sendBtn',
                    'nextBtn', 'cancelBtn', 'saveBtn', 'signinBtn', 'doneBtn', 'email', 'name', 'password','newPassword', 'error', 'message', 'banner'];

  initialize(){
    console.log('user controller');
  }

  next(event){
    event.preventDefault();
    // this.emailTarget.classList.remove('hidden');
    // this.nameTarget.classList.remove('hidden');
    // this.passwordTarget.classList.remove('hidden');
    // this.nextBtnTarget.classList.add('hidden');
    // this.cancelBtnTarget.classList.remove('hidden');
    // this.saveBtnTarget.classList.remove('hidden');
    const email = this.emailTarget.value;
    // console.log('this.emailTarget', this.emailTarget);
    // console.log('email', email);
    fetchSignInMethodsForEmail(auth, email).then((result) => {
      console.log(result);
      if(result.length > 0){
        this.showLoginView();
      } else {
        this.showSignupView();
      }
    })
    .catch((error) => {
      // console.log('error', error);
      this.signInFailure(error);
      this.hideSigninLoader();
      // ..
    });
  }

  signup(event){
    event.preventDefault();
    const email = this.emailTarget.value;
    const name = this.nameTarget.value;
    if(name === ''){
      this.showErrorMessages('auth/missing-name');
      return;
    }
    // console.log('new password target', this.newPasswordTarget);
    const password = this.newPasswordTarget.value;
    // console.log('email', email);
    // console.log('name', name);
    // console.log('password', password);
    this.showSigninLoader();
    createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed up 
      this.signInSuccessWithAuthResult(userCredential);
      this.hideSigninLoader();
      // ...
    })
    .catch((error) => {
      this.signInFailure(error);
      this.hideSigninLoader();
      // ..
    });
  }

  login(event){
    event.preventDefault();
    const email = this.emailTarget.value;
    const password = this.passwordTarget.value;
    this.showSigninLoader();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // console.log('user', user);
      this.signInSuccessWithAuthResult(userCredential);
      this.hideSigninLoader();
      // ...
    })
    .catch((error) => {
      this.signInFailure(error);
      this.hideSigninLoader();
    });
  }

  cancelSignup(event){
    event.preventDefault();
    this.showInitView();
  }

  showLoginView() {
    this.titleTarget.textContent = 'Sign in';
    this.messageTarget.classList.add('hidden');
    this.passwordContainerTarget.classList.remove('hidden');
    this.newPasswordContainerTarget.classList.add('hidden');
    this.nameContainerTarget.classList.add('hidden');
    this.errorContainerTarget.classList.add('hidden');
    this.nextBtnTarget.classList.add('hidden');
    this.cancelBtnTarget.classList.add('hidden');
    this.saveBtnTarget.classList.add('hidden');
    this.signinBtnTarget.classList.remove('hidden');
    this.forgotPasswordLinkTarget.classList.remove('hidden');
    this.sendBtnTarget.classList.add('hidden');
    console.log('this.bannerTarget', this.bannerTarget);
    this.bannerTarget.classList.add('hidden');
    console.log('this.bannerTarget', this.bannerTarget.classList);
    console.log('this.bannerTarget1', this.bannerTarget);
    this.hideSigninLoader();
  }

  showSignupView() {
    this.titleTarget.textContent = 'Create Account';
    this.messageTarget.classList.add('hidden');
    this.passwordContainerTarget.classList.add('hidden');
    this.newPasswordContainerTarget.classList.remove('hidden');
    this.nameContainerTarget.classList.remove('hidden');
    this.errorContainerTarget.classList.add('hidden');
    this.nextBtnTarget.classList.add('hidden');
    this.cancelBtnTarget.classList.remove('hidden');
    this.saveBtnTarget.classList.remove('hidden');
    this.signinBtnTarget.classList.add('hidden');
    this.forgotPasswordLinkTarget.classList.add('hidden');
    this.sendBtnTarget.classList.add('hidden');
    this.bannerTarget.classList.add('hidden');
    this.hideSigninLoader();
  }

  showInitView() {
    this.titleTarget.textContent = 'Sign in with Email';
    this.emailContainerTarget.classList.remove('hidden');
    this.messageTarget.classList.add('hidden');
    this.passwordContainerTarget.classList.add('hidden');
    this.newPasswordContainerTarget.classList.add('hidden');
    this.nameContainerTarget.classList.add('hidden');
    this.errorContainerTarget.classList.add('hidden');
    this.nextBtnTarget.classList.remove('hidden');
    this.cancelBtnTarget.classList.add('hidden');
    this.saveBtnTarget.classList.add('hidden');
    this.signinBtnTarget.classList.add('hidden');
    this.sendBtnTarget.classList.add('hidden');
    this.forgotPasswordLinkTarget.classList.add('hidden');
    this.doneBtnTarget.classList.add('hidden');
    this.bannerTarget.classList.remove('hidden');
    this.hideSigninLoader();
  }

  showResetPasswordView() {
    this.titleTarget.textContent = 'Recover Password';
    this.messageTarget.textContent = 'Get instructions sent to this email that explain how to reset your password'
    this.messageTarget.classList.remove('hidden');
    this.passwordContainerTarget.classList.add('hidden');
    this.newPasswordContainerTarget.classList.add('hidden');
    this.nameContainerTarget.classList.add('hidden');
    this.errorContainerTarget.classList.add('hidden');
    this.nextBtnTarget.classList.add('hidden');
    this.cancelBtnTarget.classList.add('hidden');
    this.saveBtnTarget.classList.add('hidden');
    this.signinBtnTarget.classList.add('hidden');
    this.sendBtnTarget.classList.remove('hidden');
    this.cancelBtnTarget.classList.remove('hidden');
    this.forgotPasswordLinkTarget.classList.add('hidden');
    this.bannerTarget.classList.add('hidden');
    this.hideSigninLoader();
  }

  sendEmail(event) {
    event.preventDefault();
    const email = this.emailTarget.value;
    console.log('email', email, email == "");
    if(email === ""){
      this.showErrorMessages('auth/email-not-valid');
      return;
    }
    this.showSigninLoader();
    sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
      // ..
      this.messageTarget.innerHTML = `Follow the instructions sent to <strong>${email}</strong> to recover your password.`;
      this.titleTarget.textContent = 'Check your email';
      this.emailContainerTarget.classList.add('hidden');
      this.messageTarget.classList.remove('hidden');
      this.doneBtnTarget.classList.remove('hidden');
      this.passwordContainerTarget.classList.add('hidden');
      this.newPasswordContainerTarget.classList.add('hidden');
      this.nameContainerTarget.classList.add('hidden');
      this.errorContainerTarget.classList.add('hidden');
      this.nextBtnTarget.classList.add('hidden');
      this.cancelBtnTarget.classList.add('hidden');
      this.saveBtnTarget.classList.add('hidden');
      this.signinBtnTarget.classList.add('hidden');
      this.sendBtnTarget.classList.add('hidden');
      this.cancelBtnTarget.classList.add('hidden');
      this.forgotPasswordLinkTarget.classList.add('hidden');
      this.bannerTarget.classList.add('hidden');
      this.hideSigninLoader();
    })
    .catch((error) => {
      this.showErrorMessages(error.code);
      this.hideSigninLoader();
      // ..
    });
    
  }

  signInSuccessWithAuthResult(authResult) {
    console.log('authResult', authResult);
    const user = authResult.user;
    console.log('user', user);
    const metadata = user.metadata;
    if (metadata.creationTime === metadata.lastSignInTime) {
      sendEmailVerification(user).then(() => {
        console.log('verification email sent');
      });
    }
    var dt = new Date();
    var timeout = 55*60*1000;
    // console.log('authResult', authResult);
    // document.cookie = "firebaseAccessToken=" + user.accessToken + '; path=/; expires='+ dt.getTime + 60*60*1000;
    cookies.set("firebaseAccessToken", user.accessToken, 365);
    cookies.set("firebaseAccessTimer", dt.getTime() + timeout, 365);
    // document.cookie = "firebaseAccessTimer=" + (dt.getTime() + timeout) + '; path=/';
    // console.log('after signin', authResult);
    // console.log('redirecturl', redirectUrl);
    window.location = '/';
    setUiToLoggedIn();
  }

  signInFailure(error) {
    setUiToLoggedOut();
    console.log('error', error);
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log('errorCode', errorCode);
    console.log('errorMessage', errorMessage);
    this.showErrorMessages(errorCode);
  }

  showSigninLoader() {
    $('#signin-container').addClass('signin-loading');
    $('.spinner').removeClass('hidden');
  }

  hideSigninLoader(){
    $('#signin-container').removeClass('signin-loading');
    $('.spinner').addClass('hidden');
  }

  showErrorMessages(errorCode){
    /** Translates an error code from Firebase Auth to a user-displayable string. */
    const errorMessages = {
      'auth/email-not-valid': 'Please enter a valid email address',
      'auth/email-already-in-use': 'The email address is already in use by another account',
      'auth/requires-recent-login': 'Please login again to perform this operation',
      'auth/too-many-requests': 'You have entered an incorrect password too many times. Please try again in a few minutes.',
      'auth/user-not-found': 'That email address doesn\'t match an existing account',
      'auth/user-token-expired':  'Please login again to perform this operation',
      'auth/weak-password': 'The password must be at least 6 characters long',
      'auth/wrong-password': 'The email and password you entered don\'t match',
      'auth/network-request-failed': 'A network error has occurred',
      'auth/password-does-not-meet-requirements': 'Missing password requirements',
      'auth/missing-password': 'Password can\'t be empty',
      'auth/missing-name': 'Name can\'t be empty',
      'auth/invalid-email': 'Please enter a valid email address'
    };
    const errorMsg = errorMessages[errorCode] || 'An error occurred';
    this.errorTarget.textContent = errorMsg;
    this.errorContainerTarget.classList.remove('hidden');
  }


}