// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { app } from './utils/firebase';
import { auth } from "../auth";
import { Controller } from "@hotwired/stimulus";
import _ from "lodash";

export default class extends Controller {
  static targets = [""];

  connect() {
    console.log('logs conroller connected');
  }

  initialize(){
    console.log('subscription controller');
    const that = this;
    auth.authStateReady().then(function(){
      if(auth.currentUser){
        console.log('auth ready current user found, load subscription data');
        that.getSubscriptionData();
      } else {
        console.log('auth ready current user not found');
        that.subscriptionDetails = {};
      }
    })
  }

  async getSubscriptionData(){
    const res = await fetch('/subscription/details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    })
    if(res.status == 401){
			console.info('401 unauthorized get subscriptionData for locked content', auth.currentUser);
			App.logout();
			return;
		}
    const data = await res.json();
    if(_.isEmpty(data)){
      return false;
    }
    // console.log('data', data);
    if(data.price_id && data.status !== 'paused'){
      cookies.erase('subscriptionPaused');
      document.getElementById('notices').innerHTML = '';
    }
    const lockedIcons = document.querySelectorAll('.locked-content');
    lockedIcons.forEach(element => {
      element.classList.add('hidden');
    });;
  }

}