import { Application } from "@hotwired/stimulus";
import subscription_controller from "./subscription_controller";
import payment_controller from "./payment_controller";
import profile_controller from "./profile_controller";
import fitness_controller from "./fitness_controller";
import overview_controller from "./overview_controller";
import activities_controller from "./activities_controller";
import home_controller from "./home_controller";
import webinar_controller from "./webinar_controller";
import lockedContent_controller from "./lockedContent_controller";
import subscriptionStatus_controller from "./subscriptionStatus_controller";
import user_controller from "./user_controller";

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application
Stimulus.register("subscription", subscription_controller);
Stimulus.register("payment", payment_controller);
Stimulus.register("profile", profile_controller);
Stimulus.register("fitness", fitness_controller);
Stimulus.register("overview", overview_controller);
Stimulus.register("lockedContent", lockedContent_controller);
Stimulus.register("activities", activities_controller);
Stimulus.register("home", home_controller);
Stimulus.register("webinar", webinar_controller);
Stimulus.register("subscriptionStatus", subscriptionStatus_controller);
Stimulus.register("user", user_controller);

// import HelloController from "./hello_controller"
// application.register("hello", HelloController)
