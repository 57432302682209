import '../vendor/jquery';

$(document).ready(function() {
	
	// Engage app-specific styling that hides all mentions of subscription and other non-app elements
	if (document.cookie.match("mobile_platform")) {
		$("body").addClass("app-active");
	}
		
	// Remove navigation for when in app
	let searchParams = new URLSearchParams(window.location.search)
	if (searchParams.has('c')) {
		$("nav#top, footer").remove();
	}
	
});
