import '../vendor/jquery';

$(document).ready(function() {
	
	
	
});

export function initTabs() {
	$(".tfm-tabs button").off('click');
	$(".tfm-tabs button").click( function(e) {
		e.preventDefault;
		const button = $(this);
		
		// Tab switching
		button.parent().find('button.active').removeClass('active');
		button.addClass('active');
		
		// Content switching
		button.parent().find('button').each( function() {
			const selector = $(this).data('tab');
			const tab = $('.' + selector);
			
			if ( $(this).hasClass('active') ) {
  			tab.removeClass('hidden');
			} else {
  			tab.addClass('hidden');
			}
			
			// scrollToActiveTab()
		});
	});
	
	scrollToActiveTab()
}

function scrollToActiveTab() {
	var container = $('.tfm-tabs');
	var activeTab = container.find('button.active');
	if (activeTab.length) {
    var containerWidth = container.outerWidth();
    var activeTabWidth = activeTab.outerWidth();
    var tabPosition = activeTab.position().left;
    var currentScroll = container.scrollLeft();
    var elementStart = tabPosition + currentScroll;
    var elementEnd = elementStart + activeTabWidth;
    if (elementStart < currentScroll || elementEnd > currentScroll + containerWidth) {
        var newScrollPosition = elementStart - (containerWidth / 2) + (activeTabWidth / 2);
        container.scrollLeft(newScrollPosition);
    }
	}
}
