import '../vendor/jquery';

$(document).ready(function(){
	
	const w = $(window);
	const nav = $("nav#top");
	const shadowClass = "shadow-block !border-black/10 scrolled";
	
	$(window).on('scroll', function() {
		if (w.scrollTop() > 10) {
    	nav.addClass(shadowClass);
		} else {
    	nav.removeClass(shadowClass);
		}
	});
	
	$('#show-drawer').click( function() {
    openDrawer();
	});
	
	$('#drawer-overlay').click( function() {
    closeDrawer();
	});
	
});

export function openDrawer() {
	showDrawer(true);
}

export function closeDrawer() {
	showDrawer(false);
}

function showDrawer(show) {
	const body = $("body");
	const wrapper = $("#drawer-wrapper");
	const overlay = $("#drawer-overlay");
	const drawer = $("nav#drawer");
	if (show) {
		wrapper.removeClass('pointer-events-none');
		overlay.removeClass('opacity-0');
		drawer.addClass('!translate-x-0 !ease-outQuint');
		body.addClass('menu-visible');
	} else {
		wrapper.addClass('pointer-events-none');
		overlay.addClass('opacity-0');
		drawer.removeClass('!translate-x-0 !ease-outQuint');
		body.removeClass('menu-visible');
	}
}
