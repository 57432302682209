import '../vendor/jquery';

$(document).ready(function() {
	const approachDistance = 200;
	const maxScale = 1.05;
	const minScale = 1.0;
	
	$(document).mousemove(function(event) {
		$('.magnify').each(function() {
			const circleOffset = $(this).offset();
			const circleWidth = $(this).width();
			const circleHeight = $(this).height();
			const circleX = circleOffset.left + circleWidth / 2;
			const circleY = circleOffset.top + circleHeight / 2;
	
			const dist = Math.sqrt(Math.pow(event.pageX - circleX, 2) + Math.pow(event.pageY - circleY, 2));
	
			let scale;
			if (dist < approachDistance) {
					scale = maxScale - (maxScale - minScale) * (dist / approachDistance);
			} else {
					scale = minScale;
			}
			
			$(this).css('transform', `scale(${scale})`);
		});
	});
});
