import '../vendor/jquery';

$(document).ready(function(){
	initUnhides();
});

export function initUnhides() {
	
	// Unhide any selector specified in the data attribute
	$('[data-unhide]').off('click');
	$("[data-unhide]").click( function(e) {
		e.preventDefault;
		
		const selector = $(this).data("unhide");
		const element = $(selector);
		
		$(this).addClass("hidden");
		element.removeClass("hidden");
	});
	
	// Unhide any selector specified in the data attribute with animation
	$('[data-unhide-animated]').off('click');
	$("[data-unhide-animated]").click( function(e) {
		e.preventDefault;
		
		const selector = $(this).data("unhide-animated");
		const element = $(selector);
		
		$(this).slideUp(250);
		element.slideDown(250);
	});
	
	// Toggle any selector specified in the data attribute using slide animation
	// Optionally add data-according name to close items in the same group
	$('[data-toggle-animated]').off('click');
	$("[data-toggle-animated]").click( function(e) {
		e.preventDefault;
		
		const active = 'active';
		const group = $(this).data("accordion");
		
		// TODO: bypass if clicking itself
		$('[data-accordion="' + group + '"].' + active).each( function() {
			$(this).removeClass(active);
			$(this).find('.aux').removeClass('opacity-1');
			$(this).find('.indicator').removeClass('rotate-180');
			$( $(this).data("toggle-animated") ).slideUp(250);
		});
		
		const selector = $(this).data("toggle-animated");
		const element = $(selector);
		
		if ( $(this).hasClass(active) ) {
			element.slideUp(250);
		} else {
			element.slideDown(250);
		}
		
		$(this).toggleClass(active);
		$(this).find('.aux').toggleClass('opacity-1');
		$(this).find('.indicator').toggleClass('rotate-180');
	});
	
}
