import '../vendor/jquery';
import { tns } from "tiny-slider/src/tiny-slider"
import 'tiny-slider/dist/tiny-slider.css';

$( document ).ready(function() {
  
  // Articles slider
  const articleSlider = ".article-slider";
  if ($(articleSlider).length) {
    $(articleSlider).each( function() {
      let container_id = $(this).attr("id");
      var slider = tns({
        container: '#' + container_id,
        loop: false,
        items: 3,
        slideBy: 1,
        mouseDrag: true,
        gutter: 16,
        fixedWidth: 290,
        edgePadding: 24,
        nav: false,
        responsive: {
          640: {
            gutter: 28,
          },
          768: {
            fixedWidth: 290,
            edgePadding: 32,
          },
          1024: {
            edgePadding: 0,
            fixedWidth: false,
          },
          1280: {
            gutter: 48,
          },
        }
      });
    });
  }
  
  // Image slider
  const imageSlider = ".heading-carousel";
  if ($(imageSlider).length) {
    var slider = tns({
      container: imageSlider,
      loop: true,
      items: 1,
      slideBy: 1,
      mouseDrag: true,
      gutter: 0,
      nav: false,
      autoHeight: true,
    });
  }
  
});
