import { auth, updateProfile, updateEmail, sendPasswordResetEmail } from "../auth";
import { Controller } from "@hotwired/stimulus";
import { startSpinner, stopSpinner } from "../ui/spinner";
import Swal from "sweetalert2";
import _ from "lodash";

export default class extends Controller {
  static targets = ['name', 'email', 'basic', 'plus', 'legacyBasic', 'legacyPlus', 'noSubscription', 'billingPrice', 'billingInterval', 'billingStartDate', 
    'billingNextDate', 'nextBillingInfo', 'cancellationInfo', 'cancellationDate', 'cancelBtn']

  connect() {
    console.log('profile connectecd');
    const that = this;
    auth.authStateReady().then(function(){
      const user = auth.currentUser;
      // console.log('user', user);
      // console.log('user name', user.displayName);
      // console.log('this.nameTarget', that.nameTarget);
      that.nameTarget.value = user.displayName || '';
      that.emailTarget.value = user.email;
      document.getElementById('account-name').getElementsByClassName('aux')[0].textContent = user.displayName;
      document.getElementById('account-email-and-amp-password').getElementsByClassName('aux')[0].textContent = user.email;
      that.user = user;
    })
  }

  initialize() {
    const that = this;
    auth.authStateReady().then(function(){
      that.getSubscriptionData();
    })
  }

  updateEmailAddress(){
    const that = this;
    if(!this.validateEmail(this.emailTarget.value)) {
      Swal.fire('Error', 'Please enter valid email address.');
      stopSpinner();
      return false;
    }
    Swal.fire({
      title: "Are you sure you want to change your email?",
      text: "Once you confirm, we’ll log you out so you can sign back in with your new email. Are you ready to proceed?",
      // icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Change My Email',
    }).then(function(result) {
      if (result.isConfirmed) {
        startSpinner();
        setTimeout(function(){
          fetch(`/account/update`, {
            method: 'post',
            body: JSON.stringify({email: that.emailTarget.value}),
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            }
          })
          .then((res) => {
            console.log('res', res);
            return res.json()
          })
          .then((json) => {
            if(json.code){
              console.log('error', json);
              Swal.fire('Error', json.message);
            } else {
              console.log('email updated', json);
              App.logout();
            }
            // Swal.fire('Email Updated!','Your email address has been successfully updated. All future communications will be sent to your new email.');
          }).catch((error) => {
            console.log('error', {error});
            Swal.fire('Error', 'Email already exists');
          })
          stopSpinner();
        }, 300);
      } else {
        console.log('cancellation alert closed')
      }
    })
  }

  updateName() {
    const that = this;
    startSpinner();
    setTimeout(function(){
      updateProfile(auth.currentUser, {
        displayName: that.nameTarget.value 
      }).then(() => {
        // console.log('name updated');
        document.getElementById('account-name').getElementsByClassName('aux')[0].textContent = that.nameTarget.value;
        Swal.fire('Name Updated!','Your full name has been successfully changed. This will be reflected across your account.');
      }).catch((error) => {
        console.log('error updating name', { error });
        Swal.fire('Error updating name', error, 'info');
      })
      stopSpinner();
    }, 300);
  }

  resetPassword() {
    const that = this;
    sendPasswordResetEmail(auth, that.emailTarget.value)
    .then(() => {
      Swal.fire('You’re All Set!','We’ve sent a password reset link to your email. Just follow the instructions to update your password.');
      // Password reset email sent!
      // ..
    })
    .catch((error) => {
      const errorMessage = error.message;
      Swal.fire('Error', errorMessage);
      // ..
    });
  }

  async getSubscriptionData(){
    startSpinner();
    const res = await fetch('/subscription/details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    })
    const data = await res.json();
    console.log('data', data);
    if(!_.isEmpty(data)){
      this.subscriptionData = data;
      this.toggleConversionBanner(data);
      if(data.price_id){
        if(data.status !== 'paused'){
          cookies.erase('subscriptionPaused');
          document.getElementById('notices').innerHTML = '';
        }
        const options = { style: 'currency', currency: data.currency };
        const price = new Intl.NumberFormat("en-BP", options).format(data.unit_amount/100);
        // console.log('this.billingPriceTarget', this.billingPriceTargets);
        if(data.plus_membership) {
          this.plusTarget.classList.remove('hidden');
          this.basicTarget.remove();
          this.legacyBasicTarget.remove();
          this.legacyPlusTarget.remove();
          this.noSubscriptionTarget.remove();
          document.getElementById('account-subscription').getElementsByClassName('aux')[0].textContent = this.plusTarget.getElementsByClassName('plan_name')[0].textContent;
        } else {
          this.basicTarget.classList.remove('hidden');
          this.plusTarget.classList.remove();
          this.legacyBasicTarget.remove();
          this.legacyPlusTarget.remove();
          this.noSubscriptionTarget.remove();
          document.getElementById('account-subscription').getElementsByClassName('aux')[0].textContent = this.basicTarget.getElementsByClassName('plan_name')[0].textContent;
        }
        this.billingPriceTarget.innerText = price;
        this.billingIntervalTarget.innerText = data.interval == 'month' ? 'Monthly' : 'Yearly';
        this.billingStartDateTarget.innerText = data.created;
        this.billingNextDateTarget.innerText = data.current_period_end;
        if(data.cancel_at_period_end){
          this.nextBillingInfoTarget.classList.add('hidden');
          this.cancelBtnTarget.classList.add('hidden');
          this.cancellationDateTarget.innerText = data.current_period_end;
          this.cancellationInfoTarget.classList.remove('hidden');
        }
      } else {
        const options = { style: 'currency', currency: data.currency };
        const price = new Intl.NumberFormat("en-BP", options).format(data.unit_amount);
        // console.log('price', price);
        this.billingPriceTarget.innerText = price;
        this.noSubscriptionTarget.classList.add('hidden');
        this.basicTarget.classList.add('hidden');
        if(data.plus_membership) {
          const plusContainer = this.legacyPlusTarget;
          const billingPriceTargets = plusContainer.querySelectorAll('.current_billing_price');
          billingPriceTargets.forEach((billingPriceTarget) => {
            // console.log('billingPriceTarget', billingPriceTarget);
            billingPriceTarget.innerText = price;
          })
          const billingIntervalTargets = plusContainer.querySelectorAll('.current_billing_period');
          billingIntervalTargets.forEach((billingIntervalTarget) => {
            billingIntervalTarget.innerText = data.interval == 'month' ? 'Monthly' : 'Yearly';
          })
          const billingStartDateTargets = plusContainer.querySelectorAll('.current_billing_start_date'); 
          billingStartDateTargets.forEach((billingStartDateTarget) => {
            billingStartDateTarget.innerText = data.created;
          })
          const billingNextDateTargets = plusContainer.querySelectorAll('.current_billing_next_date');
          billingNextDateTargets.forEach((billingNextDateTarget) => {
            billingNextDateTarget.innerText = data.current_period_end;
          })
          this.legacyBasicTarget.classList.add('hidden');
          this.legacyPlusTarget.classList.remove('hidden');
          document.getElementById('account-subscription').getElementsByClassName('aux')[0].textContent = this.legacyPlusTarget.getElementsByClassName('plan_name')[0].textContent;
        } else {
          const legacyBasicContainer = this.legacyBasicTarget;
          // console.log('legacyBasicContainer', legacyBasicContainer);
          const billingPriceTargets = legacyBasicContainer.querySelectorAll('.current_billing_price');
          billingPriceTargets.forEach((billingPriceTarget) => {
            // console.log('billingPriceTarget', billingPriceTarget);
            billingPriceTarget.innerText = price;
          })
          const billingIntervalTargets = legacyBasicContainer.querySelectorAll('.current_billing_period');
          billingIntervalTargets.forEach((billingIntervalTarget) => {
            billingIntervalTarget.innerText = data.interval == 'month' ? 'Monthly' : 'Yearly';
          })
          const billingStartDateTargets = legacyBasicContainer.querySelectorAll('.current_billing_start_date'); 
          billingStartDateTargets.forEach((billingStartDateTarget) => {
            billingStartDateTarget.innerText = data.created;
          })
          const billingNextDateTargets = legacyBasicContainer.querySelectorAll('.current_billing_next_date');
          billingNextDateTargets.forEach((billingNextDateTarget) => {
            billingNextDateTarget.innerText = data.current_period_end;
          })
          this.legacyBasicTarget.classList.remove('hidden');
          this.legacyPlusTarget.classList.add('hidden');
          document.getElementById('account-subscription').getElementsByClassName('aux')[0].textContent = this.legacyBasicTarget.getElementsByClassName('plan_name')[0].textContent;
        }
      }
    } else {
      this.toggleConversionBanner(data);
    }
    stopSpinner();
  }

  toggleConversionBanner(data = {}){
    if(data && data.legacy && data.plus_membership) {
      cookies.set('legacyPlus', true);
      document.getElementById('plus-conversion-banner').classList.remove('hidden');
    } else {
      cookies.erase('legacyPlus');
      document.getElementById('plus-conversion-banner').classList.add('hidden');
    }
  }

  cancelSubscription() {
    const data = this.subscriptionData
    const textMsg = data && (data.price_id && data.plus_membership) ? "Please note: If you choose to cancel, this plan will no longer be available, and you will not have the option to renew it in the future." : "Are you absolutely sure you want to proceed? This action cannot be undone."
    Swal.fire({
      title: "Are you sure?",
      text: textMsg,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, I am sure!',
      dangerMode: true,
    }).then(function(result) {
      if (result.isConfirmed) {
        startSpinner();
        fetch(`/subscription/cancel`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        }).then((res) => res.json())
        .then((json) => {
          setTimeout(() => {
            window.location = '/account';
          }, 300)
        }).catch((error) =>{
          console.log('error', error);
          stopSpinner();
        })
      } else {
        console.log('cancellation alert closed')
      }
    })
  }

  deleteAccount() {
    Swal.fire({
      title: "Are you sure?",
      text: "Deleting your account will permanently erase all data, including subscriptions and past purchases. This action is irreversible. Are you sure you want to proceed?",
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Remove My Account',
      dangerMode: true,
    }).then(function(result) {
      if (result.isConfirmed) {
        startSpinner();
        fetch(`/account/delete`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        }).then((res) => {
            window.location = '/';
        }).catch((error) =>{
          console.log('error', error);
          Swal.fire('Error', 'There was an error deleting your account, Please Try again!');
          stopSpinner();
        })
      } else {
        console.log('delete account alert closed')
      }
    })
  }

  validateEmail(emailAddress){
    const emailPattern = /^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/i;
    console.log('test email', emailPattern.test(emailAddress));
    if(emailPattern.test(emailAddress)) {
      return true;
    }
    return false;
  }
}